import React, { FC } from "react";
import { Text } from "@chakra-ui/react";
import { formatIntervalTime } from "@containers/DisneyDays/utils";

export interface DisneyDayActivityStepsProps {
  walkTime: string | null;
  steps: number | null;
}

const DisneyDayActivitySteps: FC<DisneyDayActivityStepsProps> = ({ walkTime, steps }) => {
  return (
    <Text position="absolute" top="-35px" left={0} fontFamily="rubik" fontSize="14px" color="brand.black">
      {formatIntervalTime(walkTime)}
      {steps ? ` (${steps} steps)` : ""}
      <Text as="span" color="brand.subtitle">
        &nbsp;from previous point
      </Text>
    </Text>
  );
};

export default DisneyDayActivitySteps;
