export enum DaysActionTypes {
  GET_DAYS_LIST = "@@DAYS/GET_DAYS_LIST",
  GET_DAYS_LIST_SUCCESS = "@@DAYS/GET_DAYS_LIST_SUCCESS",
  GET_DAYS_LIST_FAILURE = "@@DAYS/GET_DAYS_LIST_FAILURE",

  GET_DAY = "@@DAYS/GET_DAY",
  GET_DAY_SUCCESS = "@@DAYS/GET_DAY_SUCCESS",
  GET_DAY_FAILURE = "@@DAYS/GET_DAY_FAILURE",
  GET_DAY_CLEAR = "@@DAYS/GET_DAY_CLEAR",

  CREATE_DAY = "@@DAYS/CREATE_DAY",
  CREATE_DAY_SUCCESS = "@@DAYS/CREATE_DAY_SUCCESS",
  CREATE_DAY_FAILURE = "@@DAYS/CREATE_DAY_FAILURE",

  UPDATE_DAY = "@@DAYS/UPDATE_DAY",
  UPDATE_DAY_SUCCESS = "@@DAYS/UPDATE_DAY_SUCCESS",
  UPDATE_DAY_FAILURE = "@@DAYS/UPDATE_DAY_FAILURE",

  DELETE_DAY = "@@DAYS/DELETE_DAY",
  DELETE_DAY_SUCCESS = "@@DAYS/DELETE_DAY_SUCCESS",
  DELETE_DAY_FAILURE = "@@DAYS/DELETE_DAY_FAILURE",

  COPY_DAY = "@@DAYS/COPY_DAY",
  COPY_DAY_SUCCESS = "@@DAYS/COPY_DAY_SUCCESS",
  COPY_DAY_FAILURE = "@@DAYS/COPY_DAY_FAILURE",

  GET_PARKS_LIST = "@@DAYS/GET_PARKS_LIST",
  GET_PARKS_LIST_SUCCESS = "@@DAYS/GET_PARKS_LIST_SUCCESS",
  GET_PARKS_LIST_FAILURE = "@@DAYS/GET_PARKS_LIST_FAILURE",

  GET_PARK_ATTRACTIONS = "@@DAYS/GET_PARK_ATTRACTIONS",
  GET_PARK_ATTRACTIONS_SUCCESS = "@@DAYS/GET_PARK_ATTRACTIONS_SUCCESS",
  GET_PARK_ATTRACTIONS_FAILURE = "@@DAYS/GET_PARK_ATTRACTIONS_FAILURE",
  GET_PARK_ATTRACTIONS_CLEAR = "@@DAYS/GET_PARK_ATTRACTIONS_CLEAR",

  GET_PARK_CHARACTERS = "@@DAYS/GET_PARK_CHARACTERS",
  GET_PARK_CHARACTERS_SUCCESS = "@@DAYS/GET_PARK_CHARACTERS_SUCCESS",
  GET_PARK_CHARACTERS_FAILURE = "@@DAYS/GET_PARK_CHARACTERS_FAILURE",
  GET_PARK_CHARACTERS_CLEAR = "@@DAYS/GET_PARK_CHARACTERS_CLEAR",

  GET_PARK_ENTERTAINMENTS = "@@DAYS/GET_PARK_ENTERTAINMENTS",
  GET_PARK_ENTERTAINMENTS_SUCCESS = "@@DAYS/GET_PARK_ENTERTAINMENTS_SUCCESS",
  GET_PARK_ENTERTAINMENTS_FAILURE = "@@DAYS/GET_PARK_ENTERTAINMENTS_FAILURE",
  GET_PARK_ENTERTAINMENTS_CLEAR = "@@DAYS/GET_PARK_ENTERTAINMENTS_CLEAR",

  GET_PARK_RESTAURANTS = "@@DAYS/GET_PARK_RESTAURANTS",
  GET_PARK_RESTAURANTS_SUCCESS = "@@DAYS/GET_PARK_RESTAURANTS_SUCCESS",
  GET_PARK_RESTAURANTS_FAILURE = "@@DAYS/GET_PARK_RESTAURANTS_FAILURE",
  GET_PARK_RESTAURANTS_CLEAR = "@@DAYS/GET_PARK_RESTAURANTS_CLEAR",

  GET_PARK_RESTROOMS = "@@DAYS/GET_PARK_RESTROOMS",
  GET_PARK_RESTROOMS_SUCCESS = "@@DAYS/GET_PARK_RESTROOMS_SUCCESS",
  GET_PARK_RESTROOMS_FAILURE = "@@DAYS/GET_PARK_RESTROOMS_FAILURE",
  GET_PARK_RESTROOMS_CLEAR = "@@DAYS/GET_PARK_RESTROOMS_CLEAR",

  GET_PARK_AREAS = "@@DAYS/GET_PARK_AREAS",
  GET_PARK_AREAS_SUCCESS = "@@DAYS/GET_PARK_AREAS_SUCCESS",
  GET_PARK_AREAS_FAILURE = "@@DAYS/GET_PARK_AREAS_FAILURE",
  GET_PARK_AREAS_CLEAR = "@@DAYS/GET_PARK_AREAS_CLEAR",

  GET_ACTIVITY_DISTANCE = "@@DAYS/GET_ACTIVITY_DISTANCE",
  GET_ACTIVITY_DISTANCE_SUCCESS = "@@DAYS/GET_ACTIVITY_DISTANCE_SUCCESS",
  GET_ACTIVITY_DISTANCE_FAILURE = "@@DAYS/GET_ACTIVITY_DISTANCE_FAILURE",
  GET_ACTIVITY_DISTANCE_CLEAR = "@@DAYS/GET_ACTIVITY_DISTANCE_CLEAR",

  CLEAR_DAYS_LIST = "@@DAYS/CLEAR_DAYS_LIST",

  SET_FILTER = "@@DAYS/SET_FILTER",

  SET_EXECUTE_WITH_DAY_CHANGED_WARN_FUNC = "@@DAYS/SET_EXECUTE_WITH_DAY_CHANGED_WARN_FUNC",
}
