import { Button as ChakraButton, ButtonProps as ChakraButtonProps } from "@chakra-ui/react";
import { ReactNode, MouseEventHandler, FC } from "react";

interface ButtonProps extends Omit<ChakraButtonProps, "variant"> {
  onClick?: MouseEventHandler;
  children?: ReactNode;
  variant?: "primary" | "secondary" | "cancel" | "ghost";
}

const Button: FC<ButtonProps> = ({ className, children, variant, ...props }) => {
  return (
    <ChakraButton className={`button ${className || ""}`} {...props} variant={variant}>
      {children}
    </ChakraButton>
  );
};

export default Button;
