import { Attraction } from "@shared/models/Attraction";
import { Entertainment } from "@shared/models/Entertainment";
import { Restaurant } from "@shared/models/Restaurant";
import { RestRoom } from "@shared/models/RestRoom";
import { Character } from "@shared/models/Character";

export enum ActivityType {
  attraction = "attraction",
  character = "character",
  entertainment = "entertainment",
  restroom = "restroom",
  restaurant = "restaurant",
}

export interface Activity {
  readonly id: number;
  area_id: number;
  name: string;
  walk_time: string | null;
  steps: number | null;
  type: ActivityType;
  duration: string | null;
  order: number;
  attraction: Attraction | null;
  entertainment: Entertainment | null;
  restaurant: Restaurant | null;
  restroom?: RestRoom | null;
  character: Character | null;

  entertainment_id: number | null;
  restaurant_id: number | null;
  character_id: number | null;
  attraction_id: number | null;
  restroom_id: number | null;
  disney_day_id: number | null;
}
