import { Center, Text } from "@chakra-ui/react";
import React, { FC, SVGProps } from "react";
import { ReactComponent as Clock } from "@assets/files/icons/createDay/clock-filled.svg";
import { ReactComponent as Steps } from "@assets/files/icons/createDay/steps.svg";
import { ReactComponent as Attraction } from "@assets/files/icons/createDay/attraction.svg";
import { ReactComponent as Entertainment } from "@assets/files/icons/createDay/entertainment.svg";
import { ReactComponent as Character } from "@assets/files/icons/createDay/character.svg";
import { ReactComponent as Restaurant } from "@assets/files/icons/createDay/restaurant.svg";

export interface DisneyDayStatisticsCardProps {
  type: "hours" | "steps" | "attractions" | "entertainments" | "characters" | "restaurants";
  count: number;
}

const typeData: Record<
  DisneyDayStatisticsCardProps["type"],
  { icon: FC<SVGProps<SVGSVGElement>>; title: string; color?: string }
> = {
  hours: {
    icon: Clock,
    title: "Hours",
    color: "#C4FC7D",
  },
  steps: {
    icon: Steps,
    title: "Steps",
    color: "#EFB41C",
  },
  attractions: {
    icon: Attraction,
    title: "Attractions",
  },
  entertainments: {
    icon: Entertainment,
    title: "Entertainments",
  },
  characters: {
    icon: Character,
    title: "Characters",
  },
  restaurants: {
    icon: Restaurant,
    title: "Dinings",
  },
};

const DisneyDayStatisticsCard: FC<DisneyDayStatisticsCardProps> = ({ type, count }) => {
  const Icon = typeData[type].icon;
  const transformedCount = count > 1000 ? `${Math.round(count / 100) / 10}K` : count;

  return (
    <Center w="184px" h="80px" flexDirection="column" bgColor="brand.bg" borderRadius="16px" gap="6px">
      <Center w="full" h="24px">
        <Icon style={{ height: "100%", width: "auto", color: typeData[type].color }} />
        <Text as="b" fontSize="18px" ml={2} fontFamily="body">
          {transformedCount}
        </Text>
      </Center>
      <Text color="brand.actionCardTitle">{typeData[type].title}</Text>
    </Center>
  );
};

export default DisneyDayStatisticsCard;
