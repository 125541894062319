import {
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ReactComponent as Dots } from "@assets/files/icons/general/dots.svg";
import { FC, useCallback, useState, MouseEvent } from "react";

interface DisneyDayCardPopoverProps {
  onDeleteDay: () => void;
  onCopyDay: () => void;
}

const DisneyDayCardPopover: FC<DisneyDayCardPopoverProps> = (props) => {
  const { onDeleteDay, onCopyDay } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleDuplicateClick = useCallback(() => {
    onCopyDay();
    handleClose();
  }, [handleClose, onCopyDay]);

  const handleCardClick = useCallback((e: MouseEvent) => e.stopPropagation(), []);

  return (
    <Box display="flex" justifyContent="flex-end" p="8px" zIndex="1" position="absolute" top="0" right="0">
      <Popover placement="bottom-end" isOpen={isOpen} onClose={handleClose}>
        <PopoverTrigger>
          <IconButton
            icon={<Dots />}
            aria-label="Options"
            variant="ghost"
            bg="brand.cardActionsBg"
            borderRadius="16px"
            h="24px"
            maxW="24px"
            p="0px"
            minW="24px"
            onClick={handleOpen}
            _hover={{
              bg: "brand.cardActionsBg",
              opacity: "0.8",
            }}
          />
        </PopoverTrigger>
        <PopoverContent width="160px" onClick={handleCardClick}>
          <PopoverBody display="flex" flexDirection="column" alignItems="flex-start">
            <Button variant="ghost" size="sm" color="brand.primary" fontWeight="800" onClick={handleDuplicateClick}>
              Duplicate
            </Button>
            <Divider m="12px 0" />
            <Button variant="ghost" size="sm" color="brand.red" fontWeight="800" onClick={onDeleteDay}>
              Delete
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default DisneyDayCardPopover;
