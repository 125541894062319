import React from "react";
import classnames from "classnames";
import { ActionTypes, FieldItemType } from "@shared/interfaces";
import { AutocompleteInput, ErrorMessage } from "@shared/components";

import { RenderField, GenerateRenderField, Handlers } from "../../interfaces";

export interface AutocompleteInputField extends RenderField {
  type: FieldItemType.AUTOCOMPLETE_INPUT;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  loadOnFocus?: boolean;
  // eslint-disable-next-line
  onChangeField?: (field: string, value: any, values: any, setFieldValue?: (field: string, value: any) => void) => void;
  // eslint-disable-next-line
  onValueSelect?: (value: any) => void;
  noMatchesLabel?: string;
  loadingAction?: ActionTypes;
}

export const GenerateAutocompleteInput: GenerateRenderField<AutocompleteInputField & Handlers> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    handlers,
    name,
    type,
    onChangeField,
    wrapperClass,
    onValueSelect,
    ...fieldProps
  } = props;
  if (!handlers) return null;
  if (handlers && !handlers[name]) return null;
  const { getData, clearData, selectData, prepareOptionFunction } = handlers[name];
  return (
    <div key={name} id={name} className={classnames(wrapperClass)}>
      <AutocompleteInput
        {...fieldProps}
        name={name}
        inputType={type}
        value={values[name]}
        getData={getData}
        clearData={clearData}
        selectData={selectData}
        onValueSelect={onValueSelect}
        prepareOptionFunction={prepareOptionFunction}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setFieldValue={(field: string, value: any, shouldValidate?: boolean | undefined) => {
          setFieldValue(field, value, shouldValidate);
          setFieldTouched(field, true);
          onChangeField && onChangeField(field, value, setFieldValue);
        }}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
