import { generatePath } from "react-router-dom";
import { call, put, takeLatest } from "redux-saga/effects";
import { ActionWithPayload, DaysFilter } from "@shared/interfaces";
import { navigate, showNotification, startLoading, stopLoading } from "@shared/store/actions";
import {
  copyDay,
  createDay,
  deleteDay,
  getActivityDistance,
  getDay,
  getDaysList,
  getParksAreas,
  getParksAttractions,
  getParksCharacters,
  getParksEntertainments,
  getParksList,
  getParksRestaurants,
  getParksRestrooms,
  updateDay,
} from "@containers/DisneyDays/store/actions";
import { GetDisneyDaysSuccessResponse, GetDisneyDaySuccessResponse } from "@containers/DisneyDays/interfaces";
import { NameOfChildRoutes } from "@shared/constants";
import { ActivityDistance } from "@shared/models";

import api from "../api";

function* getDaysListSaga({ payload }: ActionWithPayload<DaysFilter>) {
  try {
    yield put(startLoading());
    const { days }: GetDisneyDaysSuccessResponse = yield call(api.getDayList, payload);
    yield put(getDaysList.success(days));
  } catch (error) {
    yield put(getDaysList.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getDaySaga({ payload }: ReturnType<typeof getDay.request>) {
  try {
    yield put(startLoading());
    const { day }: GetDisneyDaySuccessResponse = yield call(api.getDay, payload);
    yield put(getDay.success(day));
  } catch (error) {
    yield put(getDay.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* createDaySaga({ payload }: ReturnType<typeof createDay.request>) {
  try {
    yield put(startLoading());
    const { day }: GetDisneyDaySuccessResponse = yield call(api.createDay, payload);
    yield put(createDay.success(day));
    yield put(
      showNotification({
        message: "The Day was successfully created",
        appearance: "success",
      }),
    );
    yield put(
      navigate(
        generatePath(`${NameOfChildRoutes.DISNEY_DAYS.ROOT}${NameOfChildRoutes.DISNEY_DAYS.DETAILS}`, {
          id: String(day.id),
        }),
      ),
    );
  } catch (error) {
    yield put(createDay.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* updateDaySaga({ payload }: ReturnType<typeof updateDay.request>) {
  try {
    yield put(startLoading());
    const { day }: GetDisneyDaySuccessResponse = yield call(api.updateDay, payload);
    yield put(updateDay.success(day));
    yield put(
      showNotification({
        message: "The Day was successfully updated",
        appearance: "success",
      }),
    );
    yield put(
      navigate(
        generatePath(`${NameOfChildRoutes.DISNEY_DAYS.ROOT}${NameOfChildRoutes.DISNEY_DAYS.DETAILS}`, {
          id: String(day.id),
        }),
      ),
    );
  } catch (error) {
    yield put(updateDay.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* deleteDaySaga({ payload }: ReturnType<typeof deleteDay.request>) {
  try {
    yield put(startLoading());
    yield call(api.deleteDay, payload);
    yield put(deleteDay.success());
    yield put(getDaysList.request({}));
  } catch (error) {
    yield put(deleteDay.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* copyDaySaga({ payload }: ReturnType<typeof copyDay.request>) {
  try {
    yield put(startLoading());
    const { day }: GetDisneyDaySuccessResponse = yield call(api.copyDay, payload);
    yield put(copyDay.success(day));
  } catch (error) {
    yield put(copyDay.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getParksListSaga() {
  try {
    yield put(startLoading());
    const { parks } = yield call(api.getParksList);
    yield put(getParksList.success(parks));
  } catch (error) {
    yield put(getParksList.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getParksAttractionsSaga({ payload }: ReturnType<typeof getParksAttractions.request>) {
  try {
    yield put(startLoading());
    const { attractions } = yield call(api.getParksAttractions, payload);
    yield put(getParksAttractions.success(attractions));
  } catch (error) {
    yield put(getParksAttractions.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getParksCharactersSaga({ payload }: ReturnType<typeof getParksCharacters.request>) {
  try {
    yield put(startLoading());
    const { characters } = yield call(api.getParksCharacters, payload);
    yield put(getParksCharacters.success(characters));
  } catch (error) {
    yield put(getParksCharacters.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getParksEntertainmentsSaga({ payload }: ReturnType<typeof getParksEntertainments.request>) {
  try {
    yield put(startLoading());
    const { entertainments } = yield call(api.getParksEntertainments, payload);
    yield put(getParksEntertainments.success(entertainments));
  } catch (error) {
    yield put(getParksEntertainments.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getParksRestaurantsSaga({ payload }: ReturnType<typeof getParksRestaurants.request>) {
  try {
    yield put(startLoading());
    const { restaurants } = yield call(api.getParksRestaurants, payload);
    yield put(getParksRestaurants.success(restaurants));
  } catch (error) {
    yield put(getParksRestaurants.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getParksRestroomsSaga({ payload }: ReturnType<typeof getParksRestrooms.request>) {
  try {
    yield put(startLoading());
    const { restrooms } = yield call(api.getParksRestrooms, payload);
    yield put(getParksRestrooms.success(restrooms));
  } catch (error) {
    yield put(getParksRestrooms.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getParksAreasSaga({ payload }: ReturnType<typeof getParksAreas.request>) {
  try {
    yield put(startLoading());
    const { areas } = yield call(api.getParksAreas, payload);
    yield put(getParksAreas.success(areas));
  } catch (error) {
    yield put(getParksAreas.failure(error as Error));
  } finally {
    yield put(stopLoading());
  }
}

function* getActivityDistanceSaga({ payload }: ReturnType<typeof getActivityDistance.request>) {
  try {
    yield put(startLoading());
    const activityDistance: ActivityDistance = yield call(api.getActivityDistance, payload);
    yield put(getActivityDistance.success(activityDistance));
  } catch (error) {
    // do nothing
  } finally {
    yield put(stopLoading());
  }
}

function* daysSaga() {
  yield takeLatest(getDaysList.request, getDaysListSaga);
  yield takeLatest(getDay.request, getDaySaga);
  yield takeLatest(createDay.request, createDaySaga);
  yield takeLatest(updateDay.request, updateDaySaga);
  yield takeLatest(deleteDay.request, deleteDaySaga);
  yield takeLatest(copyDay.request, copyDaySaga);
  yield takeLatest(getParksList.request, getParksListSaga);
  yield takeLatest(getParksAttractions.request, getParksAttractionsSaga);
  yield takeLatest(getParksCharacters.request, getParksCharactersSaga);
  yield takeLatest(getParksEntertainments.request, getParksEntertainmentsSaga);
  yield takeLatest(getParksRestaurants.request, getParksRestaurantsSaga);
  yield takeLatest(getParksRestrooms.request, getParksRestroomsSaga);
  yield takeLatest(getParksAreas.request, getParksAreasSaga);
  yield takeLatest(getActivityDistance.request, getActivityDistanceSaga);
}

export default daysSaga;
