import { Box, VStack } from "@chakra-ui/react";
import { ActivityType } from "@shared/models";
import { ReactComponent as Clock } from "@assets/files/icons/createDay/clock.svg";
import { ReactComponent as SandglassInfo } from "@assets/files/icons/createDay/sandglass-info.svg";
import { ReactComponent as Height } from "@assets/files/icons/createDay/height.svg";
import { FC } from "react";
import { AnyType } from "@shared/interfaces";
import { formatIntervalTime } from "@containers/DisneyDays/utils";

import ActivityInfoItem from "./ActivityInfoItem";

export interface ActivityInfoBlockProps {
  value: AnyType;
  type: ActivityType;
  showAdditionalInfo?: boolean;
}

const ActivityInfoBlock: FC<ActivityInfoBlockProps> = (props) => {
  const { value, type, showAdditionalInfo } = props;

  if (!value) {
    return null;
  }

  return (
    <VStack alignItems="stretch" spacing={4}>
      {type === ActivityType.attraction && (
        <Box display="flex" flexWrap="wrap">
          <ActivityInfoItem
            title="Duration"
            value={"duration" in value.item ? formatIntervalTime(value.item.duration) : "-"}
            icon={<Clock />}
          />
          <ActivityInfoItem
            title="Avg Waiting time"
            value={formatIntervalTime(value.item.avg_waiting_time)}
            icon={<SandglassInfo />}
          />
          <ActivityInfoItem
            title="Min Height"
            value={"height" in value.item && value.item.height ? `${value.item.height} inches` : "Any Height"}
            icon={<Height />}
          />
        </Box>
      )}
      {type === ActivityType.character && (
        <Box display="flex" flexWrap="wrap">
          <ActivityInfoItem
            title="Duration"
            value={"duration" in value.item ? formatIntervalTime(value.item.duration) : "-"}
            icon={<Clock />}
          />
          <ActivityInfoItem
            title="Avg Waiting time"
            value={formatIntervalTime(value.item.avg_waiting_time)}
            icon={<SandglassInfo />}
          />
        </Box>
      )}
      {type === ActivityType.entertainment && (
        <Box display="flex" flexWrap="wrap">
          <ActivityInfoItem
            title="Duration"
            value={"duration" in value.item ? formatIntervalTime(value.item.duration) : "-"}
            icon={<Clock />}
          />
          <ActivityInfoItem
            title="Avg Waiting time"
            value={formatIntervalTime(value.item.avg_waiting_time)}
            icon={<SandglassInfo />}
          />
        </Box>
      )}
      {type === ActivityType.restaurant && (
        <>
          <Box display="flex" flexWrap="wrap">
            <ActivityInfoItem
              title="Duration"
              value={"duration" in value.item ? formatIntervalTime(value.item.duration) : "-"}
              icon={<SandglassInfo />}
            />
            <ActivityInfoItem
              title="Avg Waiting time"
              value={formatIntervalTime(value.item.avg_waiting_time)}
              icon={<SandglassInfo />}
            />
            <ActivityInfoItem
              title="Reservation Recommended"
              value={"has_reservation" in value.item && value.item.has_reservation ? "Yes" : "No"}
            />
            <ActivityInfoItem
              title="Mobile Order"
              value={"has_mobile_ordering" in value.item && value.item.has_mobile_ordering ? "Yes" : "No"}
            />
          </Box>
          {showAdditionalInfo && (
            <Box display="flex" alignItems="center">
              <Box textStyle="cardName" mr="20px">
                Dining Type
              </Box>
              <Box textStyle="secondarySubtitle" color="brand.black" fontWeight={700}>
                {"notes" in value.item && value.item.notes ? value.item.notes : "Any"}
              </Box>
            </Box>
          )}
        </>
      )}
      {showAdditionalInfo && type !== ActivityType.character && (
        <Box display="flex">
          <Box textStyle="cardName">Accessibility</Box>
          <Box
            display="flex"
            flexDirection="column"
            whiteSpace="pre"
            ml="16px"
            textStyle="secondarySubtitle"
            color="brand.black"
            fontWeight={700}
            mt="-3px"
          >
            {"ada" in value.item && value?.item?.ada}
          </Box>
        </Box>
      )}
      {showAdditionalInfo && (
        <Box display="flex" flexDirection="column">
          <Box textStyle="cardName">Description</Box>
          <Box textStyle="secondarySubtitle" color="brand.black" fontWeight={700}>
            {"description" in value.item && value?.item.description}
          </Box>
        </Box>
      )}
    </VStack>
  );
};

export default ActivityInfoBlock;
