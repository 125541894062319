import { FC, useCallback, useMemo, useState } from "react";
import { Box, Image } from "@chakra-ui/react";
import { DisneyDay } from "@shared/models";
import { formatTotalDayTime, getAvgWaitingTimeSeconds } from "@containers/DisneyDays/utils";
import imgPlaceholder from "@assets/files/images/general/day-card-placeholder.png";
import { getIntervalSeconds } from "@shared/utils";

import DisneyDayCardPopover from "../DisneyDayCardPopover/DisneyDayCardPopover";

export interface DisneyDayListCardProps {
  day: DisneyDay;
  onDeleteDay: (dayId: number) => void;
  onCopyDay: (dayId: number) => void;
}

const DisneyDayListCard: FC<DisneyDayListCardProps> = (props) => {
  const { day, onDeleteDay, onCopyDay } = props;

  const [imageLoadingError, setImageLoadingError] = useState(false);

  const calculateTotalHours = useMemo(() => {
    const seconds = day.activities?.reduce((totalSeconds, activity) => {
      const activityDurationSeconds = getIntervalSeconds(activity.duration);
      const activityWaitingTimeSeconds = getAvgWaitingTimeSeconds(activity);

      return totalSeconds + activityDurationSeconds + activityWaitingTimeSeconds;
    }, 0);

    return Math.round(seconds / 3600);
  }, [day.activities]);

  const handleImageLoadingError = useCallback(() => {
    setImageLoadingError(true);
  }, []);

  const onDeleteDayClickHandler = useCallback(() => {
    onDeleteDay(day.id);
  }, [day.id, onDeleteDay]);

  const onCopyDayClickHandler = useCallback(() => {
    onCopyDay(day.id);
  }, [day.id, onCopyDay]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius="base"
      boxShadow="0px 4px 6px 0px rgba(42, 31, 106, 0.12)"
      w="320px"
      position="relative"
      overflow="hidden"
    >
      <DisneyDayCardPopover onDeleteDay={onDeleteDayClickHandler} onCopyDay={onCopyDayClickHandler} />
      {day.image && !imageLoadingError ? (
        <Box w="full" h="192px" minHeight="192px" borderRadius="8px 8px 0px 0px" overflow="hidden">
          <Image src={day.image} alt={day.name} w="100%" h="100%" objectFit="cover" onError={handleImageLoadingError} />
        </Box>
      ) : (
        <Box w="full" h="192px" borderRadius="8px 8px 0px 0px" overflow="hidden">
          <Image src={imgPlaceholder} alt={day.name} w="100%" h="100%" objectFit="cover" />
        </Box>
      )}

      <Box p="16px" bg="brand.white" borderRadius="base">
        <Box textStyle="cardTitle" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          {day.name}
        </Box>
        <Box textStyle="secondarySubtitle">{formatTotalDayTime(calculateTotalHours || 0)} h</Box>
      </Box>
    </Box>
  );
};

export default DisneyDayListCard;
