import React from "react";
import { FieldItemType } from "@shared/interfaces";
import { CheckboxInput, ErrorMessage } from "@shared/components";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface CheckBoxFieldItem extends RenderField {
  type: FieldItemType.CHECKBOX;
  label: string;
  inputClass?: string;
  parentPrefix?: string;
  disabled?: boolean;
  handleClick?: () => void;
  onChange?: (date: boolean) => void;
}

export const GenerateCheckbox: GenerateRenderField<CheckBoxFieldItem> = (props) => {
  const {
    formikProps: { errors, touched, handleBlur, values, setFieldValue },
    name,
    inputClass,
    parentPrefix,
    label,
    type,
    disabled,
    handleClick,
    onChange,
    wrapperClass,
  } = props;

  const valueShape = parentPrefix ? values[parentPrefix][name] : values[name];
  // eslint-disable-next-line
  const handleChange = (e: React.ChangeEvent<any>) => {
    const name = e.target.name;
    const fieldValue = e.target.checked;

    const prefixedName = parentPrefix ? `${parentPrefix}.${name}` : name;
    if (name) {
      setFieldValue(prefixedName, fieldValue);
      onChange && onChange(fieldValue);
    }
  };

  return (
    <div key={name} id={name} className={wrapperClass}>
      <CheckboxInput
        label={label}
        type={type}
        name={name}
        className={inputClass}
        onChange={handleChange}
        onBlur={handleBlur}
        value={valueShape}
        disabled={disabled}
        handleClick={handleClick}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
