import { Box } from "@chakra-ui/react";
import { ReactComponent as Sandglass } from "@assets/files/icons/createDay/sandglass.svg";

const DisneyDayEditWaitTimeInfo = () => {
  return (
    <Box
      display="flex"
      bg="brand.waitTimeInfoBoxBg"
      borderRadius="base"
      minHeight="80px"
      justifyContent="flex-start"
      alignItems="center"
      p={4}
      mb={6}
      fontFamily="rubik"
      fontSize="14px"
    >
      <Box w="11px" h="15px">
        <Sandglass />
      </Box>
      <Box color="brand.black" ml="12px" maxW="740px">
        The Wait time averages that are shown in the Disney Day builder are averaged from historical average wait times.
        The wait times will dynamically update for the guest once they select the day on which they will be at Disney.
      </Box>
    </Box>
  );
};

export default DisneyDayEditWaitTimeInfo;
