import { FC, SVGProps } from "react";
import { ActivityType } from "@shared/models";
import { ReactComponent as Attraction } from "@assets/files/icons/createDay/attraction.svg";
import { ReactComponent as Entertainment } from "@assets/files/icons/createDay/entertainment.svg";
import { ReactComponent as Character } from "@assets/files/icons/createDay/character.svg";
import { ReactComponent as Restaurant } from "@assets/files/icons/createDay/restaurant.svg";

export const activityTypeIcon: Record<ActivityType, { icon: FC<SVGProps<SVGSVGElement>>; bgColor: string }> = {
  [ActivityType.attraction]: {
    icon: Attraction,
    bgColor: "#fcdcf1",
  },
  [ActivityType.entertainment]: {
    icon: Entertainment,
    bgColor: "#e4d9fd",
  },
  [ActivityType.character]: {
    icon: Character,
    bgColor: "#bfebe2",
  },
  [ActivityType.restaurant]: {
    icon: Restaurant,
    bgColor: "#f9e1d6",
  },
  [ActivityType.restroom]: {
    icon: () => null,
    bgColor: "white",
  },
};

export const activityTypeName: Record<ActivityType, string> = {
  [ActivityType.attraction]: "attraction",
  [ActivityType.entertainment]: "entertainment",
  [ActivityType.character]: "character",
  [ActivityType.restaurant]: "dining",
  [ActivityType.restroom]: "restroom",
};
