import { Box, Center } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import React, { FC, useCallback, useRef, useState } from "react";
import { ReactComponent as Attraction } from "@assets/files/icons/createDay/attraction.svg";
import { ReactComponent as Character } from "@assets/files/icons/createDay/character.svg";
import { ReactComponent as Restaurant } from "@assets/files/icons/createDay/restaurant.svg";
import { ReactComponent as Entertainment } from "@assets/files/icons/createDay/entertainment.svg";
import ActionCard from "@containers/DisneyDays/components/DisneyDayEditContainer/DisneyDayEditAddNewDay/ActionCard";
import { ActivityType } from "@shared/models";

export interface DisneyDayCreateAddNewDayActionProps {
  addNewActivityType: (type: ActivityType) => void;
}

const DisneyDayEditAddNewDayAction: FC<DisneyDayCreateAddNewDayActionProps> = (props) => {
  const { addNewActivityType } = props;
  const [activitiesShown, setActivitiesShown] = useState(false);
  const activitiesRef = useRef<HTMLDivElement>(null);

  const onActionTypeClick = useCallback(
    (type: ActivityType) => {
      addNewActivityType(type);
    },
    [addNewActivityType],
  );

  return (
    <Box position="relative" mt={2}>
      <Box h="full" w="24px" position="absolute" left="-33px" top={0} bgColor="white" />
      <Center
        bg="brand.primary"
        w="24px"
        h="24px"
        borderRadius="base"
        mr="8px"
        position="absolute"
        left="-33px"
        zIndex={1}
      >
        <AddIcon color="brand.white" />
      </Center>
      <Box
        fontSize="16px"
        color="brand.primary"
        fontWeight="800"
        onClick={() => {
          setActivitiesShown(!activitiesShown);
          if (!activitiesShown) {
            setTimeout(() => {
              activitiesRef.current?.scrollIntoView({ behavior: "smooth" });
            }, 0);
          }
        }}
        cursor="pointer"
      >
        Add Action
      </Box>
      {activitiesShown && (
        <Box
          h="104px"
          w="504px"
          bg="brand.bg"
          borderRadius="base"
          mt={2}
          display="flex"
          justifyContent="space-between"
          ref={activitiesRef}
        >
          <ActionCard
            title="Attraction"
            icon={<Attraction />}
            onClick={onActionTypeClick}
            type={ActivityType.attraction}
          />
          <ActionCard
            title="Entertainment"
            icon={<Entertainment />}
            onClick={onActionTypeClick}
            type={ActivityType.entertainment}
          />
          <ActionCard title="Dining" icon={<Restaurant />} onClick={onActionTypeClick} type={ActivityType.restaurant} />
          <ActionCard
            title="Character"
            icon={<Character />}
            onClick={onActionTypeClick}
            type={ActivityType.character}
          />
        </Box>
      )}
    </Box>
  );
};

export default DisneyDayEditAddNewDayAction;
