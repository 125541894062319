import { all, fork } from "redux-saga/effects";

import { saga as authSaga } from "../containers/Auth/store";
import { saga as daysSaga } from "../containers/DisneyDays/store";

const allSagas = [authSaga, daysSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
