import { Box, Center } from "@chakra-ui/react";
import React, { FC } from "react";
import { ReactComponent as Park } from "@assets/files/icons/createDay/park-icon.svg";

export interface DisneyDayCreateEnteredParkCardProps {
  title: string;
}

const DisneyDayEditEnteredParkCard: FC<DisneyDayCreateEnteredParkCardProps> = (props) => {
  const { title } = props;
  return (
    <Box position="relative" bg="brand.bg" borderRadius="base" p={4} w="full">
      <Center bgColor="white" h="24px" w="24px" borderRadius="base" zIndex={1} position="absolute" left="-33px" top={0}>
        <Park />
      </Center>
      <Box textStyle="cardName">Enter Park</Box>
      <Box textStyle="cardTitle" mt={1}>
        {title}
      </Box>
    </Box>
  );
};

export default DisneyDayEditEnteredParkCard;
