export enum AuthActionTypes {
  LOGIN = "@@AUTH/LOGIN",
  LOGIN_SUCCESS = "@@AUTH/LOGIN_SUCCESS",
  LOGIN_FAILURE = "@@AUTH/LOGIN_FAILURE",

  LOGOUT = "@@AUTH/LOGOUT",
  LOGOUT_SUCCESS = "@@AUTH/LOGOUT_SUCCESS",
  LOGOUT_FAILURE = "@@AUTH/LOGOUT_FAILURE",

  REGISTRATION = "@@AUTH/REGISTRATION",
  REGISTRATION_SUCCESS = "@@AUTH/REGISTRATION_SUCCESS",
  REGISTRATION_FAILURE = "@@AUTH/REGISTRATION_FAILURE",

  FORGOT_PASSWORD = "@@AUTH/FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS = "@@AUTH/FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE = "@@AUTH/FORGOT_PASSWORD_FAILURE",

  SET_PASSWORD = "@@AUTH/SET_PASSWORD",
  SET_PASSWORD_SUCCESS = "@@AUTH/SET_PASSWORD_SUCCESS",
  SET_PASSWORD_FAILURE = "@@AUTH/SET_PASSWORD_FAILURE",

  ACTIVATE_ACCOUNT = "@@AUTH/ACTIVATE_ACCOUNT",
  ACTIVATE_ACCOUNT_SUCCESS = "@@AUTH/ACTIVATE_ACCOUNT_SUCCESS",
  ACTIVATE_ACCOUNT_FAILURE = "@@AUTH/ACTIVATE_ACCOUNT_FAILURE",
}
