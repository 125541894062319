import React, { lazy, useEffect } from "react";
import { useRoutes, RouteObject, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import { actions as sharedActions, selectors as sharedSelectors } from "@shared/store";
import { NameOfChildRoutes, NamesOfParentRoutes } from "@shared/constants";
import { useNotification } from "@shared/hooks";
import { Loadable } from "@shared/components";
import { ModalWrapper } from "@shared/components/ModalWrapper";

import { NotFound } from "../../shared/components";
import { AuthGuard, GuestGuard } from "../../shared/guards";

const AuthContainer = Loadable(lazy(() => import("../Auth/containers/AuthContainer/AuthContainer")));
const DashboardContainer = Loadable(
  lazy(() => import("../Dashboard/containers/DashboardContainer/DashboardContainer")),
);

const routes: RouteObject[] = [
  {
    path: `${NamesOfParentRoutes.DASHBOARD}*`,
    element: (
      <AuthGuard>
        <DashboardContainer />
      </AuthGuard>
    ),
  },
  {
    path: `${NamesOfParentRoutes.AUTH}/*`,
    element: (
      <GuestGuard>
        <AuthContainer />
      </GuestGuard>
    ),
  },
  {
    path: `/`,
    element: <Navigate to={`${NameOfChildRoutes.DISNEY_DAYS.ROOT}`} />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notification = useSelector(sharedSelectors.getNotification());
  const navigateURL = useSelector(sharedSelectors.getNavigateURL());

  useEffect(() => {
    if (navigateURL) {
      navigate(navigateURL);
      dispatch(sharedActions.navigate(""));
    }
  }, [navigateURL, dispatch, navigate]);

  const content = useRoutes(routes);
  useNotification(notification);

  return (
    <Box className="App">
      <ModalWrapper />
      {content}
    </Box>
  );
};

export default App;
