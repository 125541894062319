const borderRadius = {
  radii: {
    none: "0",
    base: "8px",
    table: "12px",
    full: "9999px",
  },
};

export type Radius = typeof borderRadius;

export default borderRadius;
