import { Button } from "./button";
import { Table } from "./table";
import { Badge } from "./badge";
import { Divider } from "./divider";
import { Checkbox } from "./checkbox";
import { Textarea } from "./textarea";

export default {
  Button,
  Table,
  Badge,
  Divider,
  Checkbox,
  Textarea,
};
