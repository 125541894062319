import { Activity, ActivityType } from "@shared/models";
import { getIntervalMinutes, getIntervalSeconds } from "@shared/utils";

export const getAvgWaitingTimeSeconds = (activity: Activity) => {
  let seconds = 0;
  switch (activity.type) {
    case ActivityType.attraction:
      seconds = getIntervalSeconds(activity.attraction?.avg_waiting_time);
      break;
    case ActivityType.entertainment:
      seconds = getIntervalSeconds(activity.entertainment?.avg_waiting_time);
      break;
    case ActivityType.restaurant:
      seconds = getIntervalSeconds(activity.restaurant?.avg_waiting_time);
      break;
    case ActivityType.character:
      seconds = getIntervalSeconds(activity.character?.avg_waiting_time);
      break;
  }

  return seconds;
};

export const formatTotalDayTime = (totalDayTime: number) => {
  return parseFloat(totalDayTime.toFixed(2)) === Math.floor(totalDayTime)
    ? totalDayTime.toFixed(0)
    : totalDayTime.toFixed(2);
};

export const formatIntervalTime = (interval?: string | null) => {
  if (interval === null) {
    return "-";
  }
  const minutes = getIntervalMinutes(interval) || 1;
  return `${minutes} minute${minutes === 1 ? "" : "s"}`;
};
