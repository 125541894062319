import { StyleFunctionProps, theme as defaultTheme } from "@chakra-ui/react";

const defaultStrippedPropsFunc = defaultTheme.components.Table.variants?.striped;

export const Table = {
  variants: {
    striped: {
      table: {
        border: "1px solid",
        borderColor: "gray.50",
        bg: "brand.white",
        color: "brand.black",
      },
    },
    stripedWithTrAction: (props: StyleFunctionProps) => {
      const defaultStrippedProps = defaultStrippedPropsFunc ? defaultStrippedPropsFunc(props) : undefined;

      return {
        ...defaultStrippedProps,
        table: {
          border: "1px solid",
          borderColor: "gray.50",
          bg: "brand.white",
          color: "brand.black",
        },
        tbody: {
          ...defaultStrippedProps?.tbody,
          tr: {
            ...defaultStrippedProps?.tbody?.tr,
            cursor: "pointer",
            _hover: {
              td: {
                backgroundColor: "gray.200",
              },
            },
          },
        },
      };
    },
    wheelDescription: {
      thead: {
        tr: {
          th: {
            padding: "4px 0",
            textTransform: "none",
            fontSize: "14px",
            color: "brand.secondarySubtitle",
          },
        },
      },
      tbody: {
        tr: {
          td: {
            padding: "4px 0",
            fontSize: "16px",
            color: "brand.black",
          },
        },
      },
    },
  },
};
