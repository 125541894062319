import React from "react";
import { NavLink, useMatch } from "react-router-dom";
import { MenuItem } from "@shared/interfaces";
import { Box, Link } from "@chakra-ui/react";

interface SidebarItemProps {
  item: MenuItem;
}

const SidebarItem = (props: SidebarItemProps) => {
  const { item } = props;
  const { path, onClick } = item;

  const isActive = useMatch({ path, end: false });

  return (
    <Box
      h="48px"
      w="48px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="base"
      bg={isActive ? "brand.sidebarItemSelected" : ""}
      borderColor="brand.sidebarItemSelected"
    >
      {onClick ? (
        <Link onClick={onClick}>
          <Box>{item.icon}</Box>
        </Link>
      ) : (
        <NavLink to={path}>
          <Box>{item.icon}</Box>
        </NavLink>
      )}
    </Box>
  );
};

export default SidebarItem;
