import * as Yup from "yup";
import { DisneyDay } from "@shared/models";
import { DisneyDayFormShape } from "@containers/DisneyDays/interfaces";
import { FORM_ERROR_MESSAGES } from "@shared/constants";

export const createValidationSchema = Yup.object().shape({
  title: Yup.string().max(250, `Title ${FORM_ERROR_MESSAGES.LONG}`).required(FORM_ERROR_MESSAGES.REQUIRED),
  park: Yup.number().required(FORM_ERROR_MESSAGES.REQUIRED),
  description: Yup.string().max(500, `Description ${FORM_ERROR_MESSAGES.LONG}`),
  image: Yup.string().required(FORM_ERROR_MESSAGES.REQUIRED),
  activities: Yup.array().of(
    Yup.object({
      area_id: Yup.number().min(1),
      type: Yup.string(),
      name: Yup.string(),
      duration: Yup.string().nullable(),
      entertainment_id: Yup.number().nullable(),
      restaurant_id: Yup.number().nullable(),
      character_id: Yup.number().nullable(),
      attraction_id: Yup.number().nullable(),
      restroom_id: Yup.number().nullable(),
    }),
  ),
});

export const getInitValues = (day: DisneyDay | null, isCopying = false): DisneyDayFormShape => ({
  title: day ? `${day.name}${isCopying ? " (Copy)" : ""}` : "",
  park: day?.park?.id || null,
  description: day?.description || "",
  image: day?.image || null,
  activities:
    day?.activities?.map(({ id, disney_day_id, ...rest }) => ({
      ...rest,
      is_added: true,
    })) || [],
});

export const prepareSubmitValues = (values: DisneyDayFormShape) => {
  const heights: number[] = [];
  let steps = 0;
  const activities = values.activities.map((activity) => {
    if (activity.attraction?.height) {
      heights.push(activity.attraction.height);
    }
    steps += activity.steps || 0;
    return {
      order: activity.order,
      area_id: activity.area_id,
      type: activity.type,
      name: activity.name,
      walk_time: activity.walk_time,
      steps: activity.steps,
      duration: activity.duration,
      entertainment_id: activity.entertainment_id,
      restaurant_id: activity.restaurant_id,
      character_id: activity.character_id,
      attraction_id: activity.attraction_id,
      restroom_id: activity.restroom_id,
    };
  });
  return {
    name: values.title || "",
    description: values.description || "",
    height: heights.length > 0 ? Math.min(...heights) : null,
    park_id: values.park || 0,
    image: values.image || "",
    steps,
    activities,
  };
};
