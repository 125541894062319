import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";
import { DaysFilter } from "@shared/interfaces";
import { CreateDisneyDay, GetActivityDistanceParams, UpdateDisneyDay } from "@containers/DisneyDays/interfaces";

export default {
  getDay: (id: number) => request(METHODS.GET, API.DAY.GET_DAY(String(id)))(),
  getDayList: (payload: DaysFilter) => request(METHODS.GET, API.DAY.GET_DAY_LIST)({ params: payload }),
  createDay: (payload: CreateDisneyDay) => request(METHODS.POST, API.DAY.CREATE_DAY)(payload),
  updateDay: (payload: UpdateDisneyDay) => {
    const { id, ...rest } = payload;
    return request(METHODS.PUT, API.DAY.UPDATE_DAY(String(id)))(rest);
  },
  deleteDay: (id: number) => request(METHODS.DELETE, API.DAY.DELETE_DAY(String(id)))(),
  copyDay: (id: number) => request(METHODS.POST, API.DAY.COPY_DAY(String(id)))(),
  getParksList: () => request(METHODS.GET, API.PARK.GET_PARKS_LIST)(),
  getParksAttractions: (id: number) => request(METHODS.GET, API.PARK.GET_PARK_ATTRACTIONS(String(id)))(),
  getParksCharacters: (id: number) => request(METHODS.GET, API.PARK.GET_PARK_CHARACTERS(String(id)))(),
  getParksEntertainments: (id: number) => request(METHODS.GET, API.PARK.GET_PARK_ENTERTAINMENTS(String(id)))(),
  getParksRestaurants: (id: number) => request(METHODS.GET, API.PARK.GET_PARK_RESTAURANTS(String(id)))(),
  getParksRestrooms: (id: number) => request(METHODS.GET, API.PARK.GET_PARK_RESTROOMS(String(id)))(),
  getParksAreas: (id: number) => request(METHODS.GET, API.PARK.GET_PARK_AREAS(String(id)))(),
  getActivityDistance: ({ park_id, ...params }: GetActivityDistanceParams) =>
    request(METHODS.GET, API.PARK.GET_ACTIVITY_DISTANCE(park_id))({ params }),
};
