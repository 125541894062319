export const Badge = {
  variants: {
    cardBadge: {
      bg: "brand.badgeBg",
      padding: "3px 10px",
      borderRadius: "base",
      fontSize: "14px",
      fontWeight: 400,
      color: "brand.black",
      lineHeight: "20px",
      textTransform: "unset",
    },
  },
};
