export enum ActivityDistanceType {
  parkGate = "park_gate",
  attraction = "attraction",
  character = "character",
  entertainment = "entertainment",
  restaurant = "restaurant",
  restroom = "restroom",
}

export interface ActivityDistance {
  readonly id: number;
  steps: number;
  walk_time: string;
  from_type: ActivityDistanceType;
  from_attraction_id: number | null;
  from_character_id: number | null;
  from_entertainment_id: number | null;
  from_restaurant_id: number | null;
  from_restroom_id: number | null;
  to_type: ActivityDistanceType;
  to_attraction_id: number | null;
  to_character_id: number | null;
  to_entertainment_id: number | null;
  to_restaurant_id: number | null;
  to_restroom_id: number | null;
}
