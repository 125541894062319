import { Box, Button, Center, Flex } from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import { ActivityType, Attraction, Character, Entertainment, Restaurant } from "@shared/models";
import { InputSelect } from "@shared/components";
import { Option } from "@shared/interfaces";
import { ReactComponent as DeleteAction } from "@assets/files/icons/createDay/delete-action.svg";
import { CheckIcon } from "@chakra-ui/icons";
import { DisneyDayFormActivityShape } from "@containers/DisneyDays/interfaces";
import { activityTypeIcon, activityTypeName } from "@containers/DisneyDays/constants";
import { DisneyDayActivitySteps } from "@containers/DisneyDays/components";

import ActivityInfoBlock from "./ActivityInfoBlock";

const filterActivity = (candidate: Option<string> | null, input: string) => {
  return candidate ? candidate.label.toLowerCase().indexOf(input.toLowerCase()) > -1 : false;
};

export interface DisneyDayActivityCardEditProps {
  type: ActivityType;
  itemValue: DisneyDayFormActivityShape;
  onChangeItem: (value: DisneyDayFormActivityShape) => void;
  onRemove: () => void;
  onAdd: () => void;
  parkAttractions: Attraction[] | null;
  parkCharacters: Character[] | null;
  parkEntertainments: Entertainment[] | null;
  parkRestaurants: Restaurant[] | null;
  isLast?: boolean;
}

const typeSelectOptions = [
  { label: "Attraction", value: ActivityType.attraction },
  { label: "Character", value: ActivityType.character },
  { label: "Entertainment", value: ActivityType.entertainment },
  // { label: "Restrooms", value: ActivityType.restroom },
  { label: "Dining", value: ActivityType.restaurant },
];

const activityInitialData = {
  area_id: 0,
  name: "",
  walk_time: null,
  steps: null,
  duration: null,
  avg_waiting_time: null,
  entertainment_id: null,
  restaurant_id: null,
  character_id: null,
  attraction_id: null,
  restroom_id: null,
  is_added: false,
  attraction: null,
  entertainment: null,
  restaurant: null,
  character: null,
};

const DisneyDayActivityCardEdit: FC<DisneyDayActivityCardEditProps> = (props) => {
  const {
    type,
    onChangeItem,
    itemValue,
    parkAttractions,
    parkCharacters,
    parkEntertainments,
    parkRestaurants,
    onRemove,
    onAdd,
    isLast,
  } = props;

  const Icon = activityTypeIcon[type].icon;

  const getTypeSelectValue = useCallback((type: ActivityType) => {
    return (typeSelectOptions.find((option) => option.value === type) as Option<string>) || null;
  }, []);

  const handleTypeSelectChange = useCallback(
    (val: Option<string> | null) => {
      if (val) {
        onChangeItem({
          ...activityInitialData,
          type: val.value as ActivityType,
          order: itemValue.order,
        });
      }
    },
    [itemValue, onChangeItem],
  );

  const handleItemSelectChange = useCallback(
    (val: Option<string> | null) => {
      if (val) {
        const newActivity = {
          ...activityInitialData,
          order: itemValue.order,
          type: itemValue.type,
        };
        const selectedId = Number(val.value);
        switch (type) {
          case ActivityType.attraction: {
            const attraction = parkAttractions?.find(({ id }) => id === selectedId);
            if (attraction) {
              onChangeItem({
                ...newActivity,
                area_id: attraction.area_id,
                name: attraction.name,
                duration: attraction.duration,
                attraction_id: attraction.id,
                attraction,
              });
            }
            break;
          }
          case ActivityType.character: {
            const character = parkCharacters?.find(({ id }) => id === selectedId);
            if (character) {
              onChangeItem({
                ...newActivity,
                area_id: character.area_id,
                name: character.name,
                duration: character.duration,
                character_id: character.id,
                character,
              });
            }
            break;
          }
          case ActivityType.entertainment: {
            const entertainment = parkEntertainments?.find(({ id }) => id === selectedId);
            if (entertainment) {
              onChangeItem({
                ...newActivity,
                area_id: entertainment.area_id,
                name: entertainment.name,
                duration: entertainment.duration,
                entertainment_id: entertainment.id,
                entertainment,
              });
            }
            break;
          }
          case ActivityType.restaurant: {
            const restaurant = parkRestaurants?.find(({ id }) => id === selectedId);
            if (restaurant) {
              onChangeItem({
                ...newActivity,
                area_id: restaurant.area_id,
                name: restaurant.name,
                duration: restaurant.duration,
                restaurant_id: restaurant.id,
                restaurant,
              });
            }
            break;
          }
        }
      }
    },
    [
      itemValue.order,
      itemValue.type,
      onChangeItem,
      parkAttractions,
      parkCharacters,
      parkEntertainments,
      parkRestaurants,
      type,
    ],
  );

  const getItemSelectOptions = useMemo(() => {
    switch (type) {
      case ActivityType.attraction:
        return (
          parkAttractions?.map((attraction) => ({
            label: attraction.name,
            value: String(attraction.id),
          })) || []
        );
      case ActivityType.character:
        return (
          parkCharacters?.map((character) => ({
            label: character.name,
            value: String(character.id),
          })) || []
        );
      case ActivityType.entertainment:
        return (
          parkEntertainments?.map((entertainment) => ({
            label: entertainment.name,
            value: String(entertainment.id),
          })) || []
        );
      case ActivityType.restaurant:
        return (
          parkRestaurants?.map((restaurant) => ({
            label: restaurant.name,
            value: String(restaurant.id),
          })) || []
        );
      default:
        return [];
    }
  }, [parkAttractions, parkCharacters, parkEntertainments, parkRestaurants, type]);

  const getItemSelectValue = useMemo(() => {
    switch (type) {
      case ActivityType.attraction: {
        const attraction = parkAttractions?.find((attraction) => attraction.id === itemValue.attraction_id);
        return attraction
          ? { item: attraction, value: { label: attraction.name, value: String(attraction.id), type } }
          : null;
      }
      case ActivityType.character: {
        const character = parkCharacters?.find((character) => character.id === itemValue.character_id);
        return character
          ? { item: character, value: { label: character.name, value: String(character.id) }, type }
          : null;
      }
      case ActivityType.entertainment: {
        const entertainment = parkEntertainments?.find(
          (entertainment) => entertainment.id === itemValue.entertainment_id,
        );
        return entertainment
          ? { item: entertainment, value: { label: entertainment.name, value: String(entertainment.id) }, type }
          : null;
      }
      case ActivityType.restaurant: {
        const restaurant = parkRestaurants?.find((restaurant) => restaurant.id === itemValue.restaurant_id);
        return restaurant
          ? { item: restaurant, value: { label: restaurant.name, value: String(restaurant.id) }, type }
          : null;
      }
      default:
        return null;
    }
  }, [parkAttractions, parkCharacters, parkEntertainments, parkRestaurants, itemValue, type]);

  const withSteps = itemValue.walk_time !== null && itemValue.steps !== null;

  return (
    <Box w="100%" bg="brand.bg" borderRadius="base" p="16px" position="relative" mt={withSteps ? "37px" : 0}>
      {isLast && <Box h="full" w="24px" position="absolute" left="-33px" top={0} bgColor="white" />}
      <Center
        bg={activityTypeIcon[type].bgColor}
        h="24px"
        w="24px"
        borderRadius="base"
        zIndex={1}
        position="absolute"
        left="-33px"
        top={0}
      >
        <Icon width="16px" height="16px" />
      </Center>
      {withSteps && <DisneyDayActivitySteps walkTime={itemValue.walk_time} steps={itemValue.steps} />}
      <Flex justifyContent="space-between" mb={4} gap={4}>
        <Box flexShrink={0} w="196px">
          <InputSelect
            options={typeSelectOptions}
            name="type-select"
            value={getTypeSelectValue(type)}
            onChange={handleTypeSelectChange}
            label="Type"
            placeholder={type}
          />
        </Box>
        <Box w="full">
          <InputSelect
            options={getItemSelectOptions}
            name="item-select"
            value={getItemSelectValue?.value || null}
            onChange={handleItemSelectChange}
            label="Title"
            placeholder={`Search for ${activityTypeName[type]}`}
            filterOption={filterActivity}
          />
        </Box>
        <Button mt="auto" bg="rgba(224, 41, 63, 0.20)" borderRadius="base" w="40px" h="40px">
          <Box minWidth="24px" minHeight="24px" onClick={onRemove}>
            <DeleteAction />
          </Box>
        </Button>
      </Flex>
      {getItemSelectValue && <ActivityInfoBlock value={getItemSelectValue} type={type} showAdditionalInfo />}
      {!itemValue.is_added && (
        <Box display="flex" alignItems="center" justifyContent="flex-end" mt="24px">
          <Button variant="addActivity" isDisabled={!getItemSelectValue} onClick={onAdd}>
            <CheckIcon color="brand.white" /> &nbsp; Add
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default DisneyDayActivityCardEdit;
