import React, { FC, useCallback } from "react";
import { Box } from "@chakra-ui/react";
import { ActivityType } from "@shared/models";

export interface ActionCardProps {
  title: string;
  icon: React.ReactNode;
  onClick: (type: ActivityType) => void;
  type: ActivityType;
}

const ActionCard: FC<ActionCardProps> = (props) => {
  const { title, icon, onClick, type } = props;
  const handleCardClick = useCallback(() => {
    onClick(type);
  }, [onClick, type]);
  return (
    <Box
      w="112px"
      onClick={handleCardClick}
      fontSize="16px"
      fontWeight={600}
      lineHeight="26px"
      color="brand.actionCardTitle"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      cursor="pointer"
    >
      {icon}
      {title}
    </Box>
  );
};
export default ActionCard;
