import React, { useCallback } from "react";
import classnames from "classnames";
import { ErrorMessage, MultiSelect } from "@shared/components";

import { GenerateRenderField } from "../../interfaces";

import { SelectFieldItem } from ".";

export const GenerateMultiSelect: GenerateRenderField<SelectFieldItem> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
  } = props;

  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any) => {
      setFieldTouched(name, true);
      setFieldValue && setFieldValue(name, option);
    },
    [name, setFieldTouched, setFieldValue],
  );

  const handleRemoveValue = useCallback(
    (id: string) => {
      setFieldValue &&
        setFieldValue(
          name,
          (values[name] || []).filter(({ value }: { label: string; value: string }) => value !== id),
        );
      setFieldTouched(name, true);
    },
    [setFieldTouched, name, values, setFieldValue],
  );

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}
    >
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <MultiSelect
          options={options.map((o) => ({ label: o.label, value: String(o.value || "") })) || []}
          name={name}
          placeholder={placeholder}
          isDisabled={disabled}
          onChange={handleChange}
          value={values[name]}
          handleRemoveValue={handleRemoveValue}
        />
      </div>
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
