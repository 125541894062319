import React from "react";
import { FieldItemType } from "@shared/interfaces";
import { ErrorMessage, Textarea } from "@shared/components";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface TextAreaFieldItem extends RenderField {
  type: FieldItemType.TEXTAREA;
  placeholder: string;
  label: string;
  rows?: number;
  disabled?: boolean;
  showCharacterCounter?: boolean;
  characterCounterLimit?: number;
}

export const GenerateTextarea: GenerateRenderField<TextAreaFieldItem> = (props) => {
  const {
    name,
    wrapperClass,
    label,
    placeholder,
    formikProps: { handleChange, handleBlur, values, touched, errors },
    showCharacterCounter,
    characterCounterLimit,
    ...inputProps
  } = props;

  return (
    <div key={name} id={name}>
      <Textarea
        name={name}
        placeholder={placeholder}
        label={label}
        className={wrapperClass}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
        disabled={inputProps.disabled}
        rows={inputProps.rows}
        type={inputProps.type}
        showCharacterCounter={showCharacterCounter}
        characterCounterLimit={characterCounterLimit}
      />
      <ErrorMessage isTouched={!!touched[name]} error={errors[name]?.toString()} />
    </div>
  );
};
