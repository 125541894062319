import React from "react";
import { MenuItem } from "@shared/interfaces";
import { Box } from "@chakra-ui/react";

import SidebarItem from "../SidebarItem/SidebarItem";

interface SidebarItemsProps {
  items: MenuItem[];
}

const SidebarItems = (props: SidebarItemsProps) => {
  const { items } = props;
  return (
    <Box>
      {items.map((item) => (
        <SidebarItem key={item.name} item={item} />
      ))}
    </Box>
  );
};

export default SidebarItems;
