import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

export interface DisneyDayCreateItemWrapperProps {
  width?: string;
  minWidth?: string;
  children: React.ReactNode;
}

const DisneyDayEditItemWrapper: FC<DisneyDayCreateItemWrapperProps> = (props) => {
  const { children, width, minWidth } = props;
  return (
    <Box
      w={width}
      minWidth={minWidth ? minWidth : ""}
      display="flex"
      flexDirection="column"
      bg="brand.white"
      borderRadius="base"
      boxShadow="0px 4px 6px 0px rgba(42, 31, 106, 0.12)"
      mb={4}
      p="16px"
      // minHeight="calc(96vh - 72px)"
      // maxHeight="calc(96vh - 72px)"
      overflowY="auto"
    >
      {children}
    </Box>
  );
};

export default DisneyDayEditItemWrapper;
