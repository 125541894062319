import { Box, Button, Flex, FormLabel, HStack, Image, Textarea } from "@chakra-ui/react";
import { FieldArray, Formik, FormikProps } from "formik";
import {
  CreateDisneyDay,
  DisneyDayFormActivityShape,
  DisneyDayFormShape,
  GetActivityDistanceParams,
} from "@containers/DisneyDays/interfaces";
import {
  createValidationSchema,
  getInitValues,
  prepareSubmitValues,
} from "@containers/DisneyDays/components/DisneyDayEditContainer/DisneyDayEdit/formHelpers";
import React, { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ActivityDistanceType, ActivityType, DisneyDay } from "@shared/models";
import { ReactComponent as PlaceholderCastle } from "@assets/files/images/general/create-day-placeholder.svg";
import { ReactComponent as AddActivity } from "@assets/files/icons/createDay/add-activity.svg";
import { ReactComponent as AddActivityActive } from "@assets/files/icons/createDay/add-activity-active.svg";
import { ReactComponent as Delete } from "@assets/files/icons/general/delete.svg";
import { ReactComponent as Edit } from "@assets/files/icons/general/edit.svg";
import Input from "@shared/components/Field/Input/Input";
import { Park } from "@shared/models/Park";
import InputSelect from "@shared/components/InputSelect/InputSelect";
import { Option } from "@shared/interfaces";
import { useDispatch } from "react-redux";
import { showModal } from "@shared/store/actions";
import { MODAL_TYPES } from "@shared/constants";
import { AddIcon } from "@chakra-ui/icons";
import {
  DisneyDayActivityCardEdit,
  DisneyDayEditAddNewDayAction,
  DisneyDayEditEnteredParkCard,
  DisneyDayEditHeader,
  DisneyDayEditItemWrapper,
  DisneyDayEditWaitTimeInfo,
} from "@containers/DisneyDays/components";
import { setExecuteWithDayChangedWarning } from "@containers/DisneyDays/store/actions";

import useGetParkItemsInfo from "../../../hooks/getParkItemsInfo";
import DisneyDayActivityCard from "../../DisneyDayViewContainer/DisneyDayActivityCard/DisneyDayActivityCard";

type ActivityDistanceDirectionField =
  | "from_attraction_id"
  | "from_character_id"
  | "from_entertainment_id"
  | "from_restaurant_id"
  | "from_restroom_id"
  | "to_attraction_id"
  | "to_character_id"
  | "to_entertainment_id"
  | "to_restaurant_id"
  | "to_restroom_id";

interface DisneyDayEditProps {
  onSubmit: (values: CreateDisneyDay) => void;
  onBackClick: () => void;
  disneyDay: DisneyDay | null;
  parks: Park[] | null;
  isEditing: boolean;
}

const DisneyDayEdit: FC<DisneyDayEditProps> = (props) => {
  const dispatch = useDispatch();
  const { onSubmit, disneyDay, parks, onBackClick, isEditing } = props;

  const formValues = useMemo(() => getInitValues(disneyDay, !isEditing), [disneyDay, isEditing]);
  const formikRef = useRef<FormikProps<DisneyDayFormShape>>(null);
  const containerBottomRef = useRef<HTMLDivElement>(null);

  const [addingActivities, setAddingActivities] = useState<boolean>(false);
  const [selectedParkId, setSelectedParkId] = useState<number | null>(formValues.park || null);
  const [isSaving, setIsSaving] = useState(false);
  const {
    parkAttractions,
    parkCharacters,
    parkEntertainments,
    parkRestaurants,
    currentDistance,
    findActivityDistance,
    cleanUp,
  } = useGetParkItemsInfo(selectedParkId);

  useEffect(() => {
    setSelectedParkId(formValues.park || null);
  }, [formValues.park]);

  useEffect(() => {
    if (currentDistance && formikRef.current && currentDistance.to_type !== ActivityDistanceType.parkGate) {
      const fromId = currentDistance[`from_${currentDistance.from_type}_id` as ActivityDistanceDirectionField];
      const toId = currentDistance[`to_${currentDistance.to_type}_id` as ActivityDistanceDirectionField];
      formikRef.current.values.activities.forEach((activity, i, values) => {
        const prevActivity = values[i - 1];
        const currentKey = `${currentDistance.to_type}_id`;
        const prevKey = `${currentDistance.from_type}_id`;

        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          activity[currentKey] === toId &&
          ((!prevActivity && currentDistance.from_type === ActivityDistanceType.parkGate) ||
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            prevActivity?.[prevKey] === fromId)
        ) {
          formikRef.current?.setFieldValue(`activities.${i}`, {
            ...activity,
            walk_time: currentDistance.walk_time,
            steps: currentDistance.steps,
          });
        }
      });
    }
  }, [currentDistance]);

  const parksOptions = useMemo<Option<string>[]>(() => {
    return parks
      ? parks.map((park) => ({
          label: park.name,
          value: String(park.id),
        }))
      : [];
  }, [parks]);

  const scrollToBottom = useCallback(() => {
    setTimeout(() => {
      containerBottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
  }, []);

  const getSelectedParkOption = useCallback(
    (parkId: number | null) => {
      return (parkId && parksOptions.find((option) => Number(option.value) === Number(parkId))) || null;
    },
    [parksOptions],
  );

  const handleUploadPicture = useCallback((file: string) => {
    formikRef.current?.setFieldValue("image", file);
  }, []);

  const uploadPictureModalHandler = useCallback(() => {
    dispatch(
      showModal({
        type: MODAL_TYPES.UPLOAD_IMAGE,
        closeOnOverlayClick: false,
        size: "lg",
        props: {
          onSubmit: handleUploadPicture,
          image: formikRef.current?.values.image,
        },
      }),
    );
  }, [dispatch, handleUploadPicture]);

  const handleStartAddingActivities = useCallback(() => {
    setAddingActivities(true);
  }, []);

  const handleAddNewDayActivity = useCallback(
    (activityType: ActivityType) => {
      const formikValues = formikRef.current?.values;

      if (formikValues) {
        formikRef.current.setFieldValue("activities", [
          ...(formikValues.activities || []),
          {
            name: "",
            area_id: null,
            type: activityType,
            order: (formikValues?.activities?.length || 0) + 1,
            duration: "",
            entertainment_id: null,
            restaurant_id: null,
            character_id: null,
            attraction_id: null,
            restroom_id: null,
            disney_day_id: null,
            is_added: false,
          },
        ]);
      }
      scrollToBottom();
    },
    [scrollToBottom],
  );

  const handleChangePark = useCallback(
    (option: Option<string> | null) => {
      formikRef.current?.setFieldValue("activities", []);
      formikRef.current?.setFieldValue("park", option?.value);
      setSelectedParkId(Number(option?.value || null));
      cleanUp();
      if (formikRef.current?.values.activities?.length) {
        setAddingActivities(true);
      }
    },
    [cleanUp],
  );

  const executeWithDayChangedWarning = useCallback(
    (func: () => void) => {
      if (JSON.stringify(formikRef.current?.values) === JSON.stringify(formValues)) {
        func();
      } else {
        dispatch(
          showModal({
            type: MODAL_TYPES.CONFIRM,
            props: {
              heading: "Are you sure you want to leave this day?",
              content: "You have unsaved content, and will be lost unless you save it.",
              cancelBtnText: "Cancel",
              successBtnText: "Leave",
              onSuccess: func,
            },
          }),
        );
      }
    },
    [dispatch, formValues],
  );

  useEffect(() => {
    dispatch(setExecuteWithDayChangedWarning(executeWithDayChangedWarning));
  }, [dispatch, executeWithDayChangedWarning]);

  const handleBack = useCallback(() => {
    executeWithDayChangedWarning(onBackClick);
  }, [executeWithDayChangedWarning, onBackClick]);

  const generateDistanceParams = useCallback(
    (fromActivity: DisneyDayFormActivityShape, toActivity: DisneyDayFormActivityShape) => {
      if (!selectedParkId) {
        return;
      }
      const params: Partial<GetActivityDistanceParams> = {
        park_id: selectedParkId,
      };
      ["from", "to"].forEach((prefix) => {
        const activity = prefix === "from" ? fromActivity : toActivity;
        const typeKey = `${prefix}_type` as "from_type" | "to_type";

        if (!activity) {
          const park = parks?.find(({ id }) => id === selectedParkId);
          params[typeKey] = ActivityDistanceType.parkGate;
          params[`${prefix}_park_gate_id` as ActivityDistanceDirectionField] = park?.gates?.[0]?.id;
        } else if (activity.attraction_id) {
          params[typeKey] = ActivityDistanceType.attraction;
          params[`${prefix}_attraction_id` as ActivityDistanceDirectionField] = activity.attraction_id;
        } else if (activity.character_id) {
          params[typeKey] = ActivityDistanceType.character;
          params[`${prefix}_character_id` as ActivityDistanceDirectionField] = activity.character_id;
        } else if (activity.entertainment_id) {
          params[typeKey] = ActivityDistanceType.entertainment;
          params[`${prefix}_entertainment_id` as ActivityDistanceDirectionField] = activity.entertainment_id;
        } else if (activity.restaurant_id) {
          params[typeKey] = ActivityDistanceType.restaurant;
          params[`${prefix}_restaurant_id` as ActivityDistanceDirectionField] = activity.restaurant_id;
        }
      });

      return params as GetActivityDistanceParams;
    },
    [parks, selectedParkId],
  );

  return (
    <Formik
      initialValues={formValues}
      onSubmit={(values, { setSubmitting }) => {
        setIsSaving(true);
        onSubmit(prepareSubmitValues(values));
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      validateOnBlur={false}
      validationSchema={createValidationSchema}
      validateOnChange={true}
      validateOnMount={true}
    >
      {({ values, handleSubmit, setFieldValue, isValid }) => {
        const allAdded = values.activities.every((a) => a.is_added);
        return (
          <form onSubmit={handleSubmit} style={{ height: "100%" }}>
            <DisneyDayEditHeader
              onBackClick={handleBack}
              isButtonEnabled={isValid && !isSaving}
              isEditing={isEditing}
            />
            <Flex gap="16px" overflowY="auto" h="calc(100% - 72px)" alignItems="stretch">
              <DisneyDayEditItemWrapper width="24%" minWidth="424px">
                <Box textStyle="editMainCardTitle" mb={3}>
                  GENERAL
                </Box>
                <Box
                  position="relative"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  width="full"
                  height="240px"
                  flexShrink={0}
                >
                  {values.image ? (
                    <>
                      <Image src={values.image} alt="Uploaded" w="100%" h="100%" borderRadius="8px" objectFit="cover" />
                      <Box display="flex" flexDirection="column" position="absolute" top="8px" right="8px" gap="12px">
                        <Button
                          variant="imageActionButton"
                          bg="brand.editImageBg"
                          h="24px"
                          onClick={uploadPictureModalHandler}
                        >
                          <Edit />
                        </Button>
                        <Button
                          variant="imageActionButton"
                          bg="brand.deleteImageBg"
                          onClick={() => setFieldValue("image", "")}
                        >
                          <Delete />
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box borderRadius="8px">
                        <PlaceholderCastle />
                      </Box>
                      <Button
                        variant="ghost"
                        color="brand.primary"
                        position="absolute"
                        onClick={uploadPictureModalHandler}
                      >
                        <AddIcon color="brand.primary" />
                        &nbsp; Add Cover Image*
                      </Button>
                    </>
                  )}
                </Box>
                <Box mb="24px">
                  <Input
                    name="name"
                    label="Title*"
                    placeholder="Title of Disney Day"
                    value={values.title || ""}
                    onChange={(e) => {
                      setFieldValue("title", e.target.value);
                    }}
                  />
                </Box>
                <Box mb="24px">
                  <InputSelect
                    placeholder="Select the Park"
                    options={parksOptions}
                    name="park"
                    label="Park*"
                    value={getSelectedParkOption(values.park)}
                    onChange={handleChangePark}
                    isDisabled={isEditing}
                  />
                </Box>
                <Box mb="24px">
                  <FormLabel color="brand.label" fontSize="14px">
                    Description
                  </FormLabel>
                  <Textarea
                    variant="textAreaCreate"
                    resize="none"
                    maxLength={500}
                    name="description"
                    placeholder="Description"
                    value={values.description || ""}
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                  />
                </Box>
              </DisneyDayEditItemWrapper>
              <DisneyDayEditItemWrapper width="full">
                <Box textStyle="editMainCardTitle" mb={3}>
                  ITINERARY
                </Box>
                {!values.activities.length && !addingActivities ? (
                  <Box w="100%" h="calc(96vh - 50px)" display="flex" justifyContent="center" alignItems="center">
                    {isValid ? (
                      <Box ml="-22px" cursor="pointer">
                        <AddActivityActive onClick={handleStartAddingActivities} />
                      </Box>
                    ) : (
                      <AddActivity />
                    )}
                  </Box>
                ) : (
                  <Box w="100%" h="calc(96vh - 50px)" overflowY="auto">
                    <DisneyDayEditWaitTimeInfo />
                    <HStack alignItems="stretch" spacing={5}>
                      <Box
                        borderRight={values.activities.length ? "2px dashed rgba(152, 135, 255, 0.40)" : undefined}
                        ml={3}
                        flexShrink={0}
                      />
                      <Flex flexDirection="column" gap={4} w="full" alignItems="flex-start">
                        <DisneyDayEditEnteredParkCard title={getSelectedParkOption(values.park)?.label || ""} />
                        <FieldArray
                          name="activities"
                          render={() => (
                            <>
                              {values.activities.map((activity, index) => (
                                <Fragment key={index}>
                                  {activity.is_added ? (
                                    <DisneyDayActivityCard
                                      activity={activity}
                                      onEdit={() => setFieldValue(`activities.${index}.is_added`, false)}
                                      onRemove={() => {
                                        setFieldValue(
                                          "activities",
                                          values.activities.filter((_, i) => i !== index),
                                        );
                                      }}
                                      isLast={!allAdded && index === values.activities.length - 1}
                                    />
                                  ) : (
                                    <Box display="flex" w="100%">
                                      <DisneyDayActivityCardEdit
                                        type={activity.type as ActivityType}
                                        parkAttractions={parkAttractions}
                                        parkCharacters={parkCharacters}
                                        parkEntertainments={parkEntertainments}
                                        parkRestaurants={parkRestaurants}
                                        onChangeItem={(value: DisneyDayFormActivityShape) => {
                                          setFieldValue(`activities.${index}`, value);
                                          if (value.area_id) {
                                            const params = generateDistanceParams(values.activities[index - 1], value);
                                            if (params) {
                                              findActivityDistance(params);
                                            }
                                          }
                                          if (index === values.activities.length - 1) {
                                            scrollToBottom();
                                          }
                                        }}
                                        itemValue={activity}
                                        onRemove={() => {
                                          setFieldValue(
                                            "activities",
                                            values.activities.filter((_, i) => i !== index),
                                          );
                                        }}
                                        onAdd={() => {
                                          setFieldValue(`activities.${index}.is_added`, true);
                                        }}
                                        isLast={index === values.activities.length - 1}
                                      />
                                    </Box>
                                  )}
                                </Fragment>
                              ))}
                              {allAdded && (
                                <DisneyDayEditAddNewDayAction addNewActivityType={handleAddNewDayActivity} />
                              )}
                            </>
                          )}
                        />
                      </Flex>
                    </HStack>
                    <Box h="1px" ref={containerBottomRef} />
                  </Box>
                )}
              </DisneyDayEditItemWrapper>
            </Flex>
          </form>
        );
      }}
    </Formik>
  );
};

export default DisneyDayEdit;
