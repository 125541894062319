import { Box, Button } from "@chakra-ui/react";
import { CheckIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { FC, useCallback } from "react";

export interface DisneyDayCreateHeaderProps {
  onBackClick: () => void;
  isEditing: boolean;
  isButtonEnabled?: boolean;
}

const DisneyDayEditHeader: FC<DisneyDayCreateHeaderProps> = (props) => {
  const { onBackClick, isEditing, isButtonEnabled } = props;

  const onBackClickHandler = useCallback(() => {
    onBackClick();
  }, [onBackClick]);

  return (
    <Box h="72px" w="100%" display="flex" alignItems="center" justifyContent="space-between">
      <Box textStyle="viewAndEditTitle" onClick={onBackClickHandler} cursor="pointer">
        <ChevronLeftIcon color="brand.subtitle" mb="3px" />
        {isEditing ? "Edit" : "Create New"} Disney Day
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" w="30%" minWidth="350px">
        <Box w="240px" minW="240" mr="8px"></Box>
        <Button variant="secondary" w="94" type="submit" isDisabled={!isButtonEnabled}>
          <CheckIcon boxSize="16px" mr="8px" /> Save
        </Button>
      </Box>
    </Box>
  );
};

export default DisneyDayEditHeader;
