import { AppState } from "@shared/interfaces";
import { createSelector } from "reselect";

const selectDay = (state: AppState) => state.days;

export const getDaysList = () => createSelector(selectDay, (state) => state.days);
export const getFilter = () => createSelector(selectDay, (state) => state.filter);
export const getDay = () => createSelector(selectDay, (state) => state.day);
export const getParks = () => createSelector(selectDay, (state) => state.parks);
export const getAttractions = () => createSelector(selectDay, (state) => state.attractions);
export const getCharacters = () => createSelector(selectDay, (state) => state.characters);
export const getEntertainments = () => createSelector(selectDay, (state) => state.entertainments);
export const getRestaurants = () => createSelector(selectDay, (state) => state.restaurants);
export const getRestrooms = () => createSelector(selectDay, (state) => state.restrooms);
export const getAreas = () => createSelector(selectDay, (state) => state.areas);
export const selectActivityDistance = () => createSelector(selectDay, (state) => state.distance);
