import { Box, Button, ModalBody, ModalFooter, ModalHeader } from "@chakra-ui/react";
import { FC, useCallback, useState } from "react";
import { ImagesUpload } from "@shared/components/Field/ImagesUpload/ImagesUpload";
import { ACCEPT_IMAGE_TYPES } from "@shared/constants";
import { CheckIcon } from "@chakra-ui/icons";

export interface DisneyDayCreateUploadImageModalProps {
  onClose: () => void;
  onSubmit: (values: string) => void;
  image: string;
}

const MAX_FILE_SIZE = 10;

const DisneyDayEditUploadImageModal: FC<DisneyDayCreateUploadImageModalProps> = (props) => {
  const { onClose, onSubmit, image } = props;
  const [file, setFile] = useState<string>(image);

  const handleUploadFiles = useCallback((files: string[]) => {
    setFile(files[0]);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!file) return;
    onSubmit(file);
    onClose();
  }, [file, onClose, onSubmit]);

  return (
    <>
      <ModalHeader>
        <Box display="flex" flexDirection="column">
          <Box>Add Image</Box>
          <Box fontSize="14px" lineHeight="20px" color="brand.subtitle" fontWeight="400" mt="8px">
            Recommended image size is 1600 x 900 pixels. <br />
            The file should not be bigger than 10Mb
          </Box>
        </Box>
      </ModalHeader>
      <ModalBody>
        <ImagesUpload
          types={ACCEPT_IMAGE_TYPES}
          maxFileSize={MAX_FILE_SIZE}
          onUploadFile={handleUploadFiles}
          maxFiles={1}
          initialFiles={file ? [file] : []}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="cancel" mr={3} onClick={() => onClose()}>
          Cancel
        </Button>
        <Button variant="primary" isDisabled={!file} onClick={handleSubmit}>
          <CheckIcon color="white" /> &nbsp; Add
        </Button>
      </ModalFooter>
    </>
  );
};

export default DisneyDayEditUploadImageModal;
