import { Box, Button } from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { AddIcon } from "@chakra-ui/icons";

export interface DisneyDaysListHeaderProps {
  onAddNewDay: () => void;
}

const DisneyDaysListHeader: FC<DisneyDaysListHeaderProps> = (props) => {
  const { onAddNewDay } = props;

  const onAddNewDayClickHandler = useCallback(() => {
    onAddNewDay();
  }, [onAddNewDay]);
  return (
    <Box h="72px" w="100%" display="flex" alignItems="center" justifyContent="space-between" px={6}>
      <Box textStyle="containerTitle">Disney Days</Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" w="30%" minWidth="350px">
        <Button variant="primary" w="125px" onClick={onAddNewDayClickHandler}>
          <AddIcon boxSize="16px" mr="8px" /> Add New
        </Button>
      </Box>
    </Box>
  );
};

export default DisneyDaysListHeader;
