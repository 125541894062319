import React from "react";
import { Box } from "@chakra-ui/react";

import MainSidebar, { MainSidebarProps } from "./components/MainSidebar/MainSidebar";

const Sidebar = (props: MainSidebarProps) => {
  const { items, onLogoutClick, user } = props;

  return (
    <Box h="100%" w="64px" bg="brand.white" boxShadow="1px 0px 5px 0px rgba(0, 0, 0, 0.10)">
      <MainSidebar items={items} onLogoutClick={onLogoutClick} user={user} />
    </Box>
  );
};

export default Sidebar;
