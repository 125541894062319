const colors = {
  brand: {
    bg: "#F6F6F9",
    loginBg: "radial-gradient(207.31% 96.00% at 50.00% 4.00%, #2D2C56 34.43%, #0B0B2F 100%)",
    primary: "#6A6FF7",
    subtitle: "#8D95B2",
    black: "#181829",
    badgeBg: "#E1E4FF",
    secondaryButtonBg: "#8D95B2",
    secondaryGreen: "#00B78B",
    imageUploadBg: "rgba(223, 223, 237, 0.4)",
    secondaryButtonBgActive: "#00B78B",
    orange: "#EF8F59",
    secondary: "#A71C27",
    white: "#FFFFFF",
    cancel: "#2D2C56",
    red: "#D72A49",
    editImageBg: "rgba(24, 24, 41, 0.6)",
    deleteImageBg: "rgba(215, 42, 73, 0.6)",
    waitTimeInfoBoxBg: "rgba(89, 166, 255, 0.2)",
    actionCardTitle: "#2D2C56",
    checkMark: "#46BA90",
    secondarySubtitle: "#7E7E7E",
    label: "#8D95B2",
    sidebarBackground: "#202020",
    sidebarItemSelected: "#E1E4FF",
    inputFocusBorder: "#7E7E7E",
    cardActionsBg: "rgba(24, 24, 41, 0.6)",
  },
  gray: {
    40: "#F1F1F1",
    50: "#E7E7E7",
    60: "#EDEDED",
    100: "#F1F1F1",
  },
} as const;

export type Colors = typeof colors;
export default colors;
