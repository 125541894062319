import { Box, Button, Center, Collapse, Flex, HStack, Text, useDisclosure } from "@chakra-ui/react";
import React, { FC, useMemo } from "react";
import { ReactComponent as ChevronBottom } from "@assets/files/icons/general/chevron-bottom.svg";
import { ReactComponent as ChevronTop } from "@assets/files/icons/general/chevron-top.svg";
import { ReactComponent as Clock } from "@assets/files/icons/createDay/clock.svg";
import { ReactComponent as SandglassInfo } from "@assets/files/icons/createDay/sandglass-info.svg";
import { ReactComponent as Height } from "@assets/files/icons/createDay/height.svg";
import { ReactComponent as Delete } from "@assets/files/icons/general/delete-red.svg";
import { ReactComponent as Edit } from "@assets/files/icons/general/edit-blue.svg";
import { Activity, ActivityType } from "@shared/models";
import { activityTypeIcon, activityTypeName } from "@containers/DisneyDays/constants";
import { DisneyDayActivitySteps } from "@containers/DisneyDays/components";
import { formatIntervalTime } from "@containers/DisneyDays/utils";

export interface DisneyDayActivityCardProps {
  activity: Omit<Activity, "id" | "disney_day_id" | "area_id">;
  isLast?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
}

interface ItemData {
  description: string | null;
  accessibility: string | null;
  avg_waiting_time: string | null;
  height: number | null;
  has_reservation: string;
  has_mobile_ordering: string;
}

const DisneyDayActivityCard: FC<DisneyDayActivityCardProps> = ({ activity, isLast, onEdit, onRemove }) => {
  const { isOpen, onToggle } = useDisclosure();
  const Icon = activityTypeIcon[activity.type].icon;

  const itemData = useMemo(() => {
    const data: ItemData = {
      description: "",
      accessibility: "",
      avg_waiting_time: null,
      height: null,
      has_reservation: "",
      has_mobile_ordering: "",
    };
    switch (activity.type) {
      case ActivityType.attraction:
        data.description = activity.attraction?.description || "";
        data.accessibility = activity.attraction?.ada || "";
        data.avg_waiting_time = activity.attraction?.avg_waiting_time || "";
        data.height = activity.attraction?.height || 0;
        break;
      case ActivityType.entertainment:
        data.description = activity.entertainment?.description || "";
        data.accessibility = activity.entertainment?.ada || "";
        data.avg_waiting_time = activity.entertainment?.avg_waiting_time || "";
        break;
      case ActivityType.restaurant:
        data.description = activity.restaurant?.description || "";
        data.accessibility = activity.restaurant?.ada || "";
        data.avg_waiting_time = activity.restaurant?.avg_waiting_time || "";
        data.has_reservation = activity.restaurant?.has_reservations ? "Yes" : "No";
        data.has_mobile_ordering = activity.restaurant?.has_mobile_ordering ? "Yes" : "No";
        break;
      case ActivityType.character:
        data.avg_waiting_time = activity.character?.avg_waiting_time || "";
        break;
    }

    return data;
  }, [activity]);

  const withSteps = activity.walk_time !== null && activity.steps !== null;

  return (
    <Box position="relative" bg="brand.bg" borderRadius="base" p={4} w="full" mt={withSteps ? "37px" : 0}>
      {isLast && <Box h="full" w="24px" position="absolute" left="-33px" top={0} bgColor="white" />}
      <Center
        bg={activityTypeIcon[activity.type].bgColor}
        h="24px"
        w="24px"
        borderRadius="base"
        zIndex={1}
        position="absolute"
        left="-33px"
        top={0}
      >
        <Icon width="16px" height="16px" />
      </Center>
      {withSteps && <DisneyDayActivitySteps walkTime={activity.walk_time} steps={activity.steps} />}
      <HStack spacing={4} fontFamily="rubik" mr={itemData.accessibility || onEdit ? 0 : 8}>
        <Box textStyle="cardName" mr="auto" w="100px">
          {activityTypeName[activity.type]}
        </Box>
        <HStack spacing={4} w="full" justifyContent="flex-end" flexWrap="wrap">
          <Center gap={1}>
            <Clock />
            <Box textStyle="cardName" mr={1}>
              Duration
            </Box>
            <Text>{formatIntervalTime(activity.duration)}</Text>
          </Center>
          {itemData.avg_waiting_time && (
            <Center gap={1}>
              <SandglassInfo />
              <Box textStyle="cardName" mr={1}>
                Avg Waiting time
              </Box>
              <Text>{formatIntervalTime(itemData.avg_waiting_time)}</Text>
            </Center>
          )}
          {itemData.height !== null && (
            <Center gap={1}>
              <Height />
              <Box textStyle="cardName" mr={1}>
                Min Height
              </Box>
              <Text>{itemData.height ? `${itemData.height} inches` : "Any Height"}</Text>
            </Center>
          )}
          {itemData.has_reservation && (
            <Center gap={2}>
              <Box textStyle="cardName">Reservation Recommended</Box>
              <Text>{itemData.has_reservation}</Text>
            </Center>
          )}
          {itemData.has_mobile_ordering && (
            <Center gap={2}>
              <Box textStyle="cardName">Mobile Order</Box>
              <Text>{itemData.has_mobile_ordering}</Text>
            </Center>
          )}
        </HStack>
        {(itemData.accessibility || onEdit) && (
          <Box onClick={onToggle} h="16px" w="16px" cursor="pointer">
            {isOpen ? <ChevronTop /> : <ChevronBottom />}
          </Box>
        )}
      </HStack>
      <Box textStyle="cardTitle" mt={1}>
        {activity.name}
      </Box>
      {itemData.description && (
        <Box textStyle="cardName" mt={4}>
          {itemData.description}
        </Box>
      )}
      <Collapse in={isOpen} animateOpacity>
        <Flex gap={4} alignItems="flex-start" mt={4}>
          {itemData.accessibility && (
            <>
              <Box textStyle="cardName" lineHeight="26px">
                Accessibility
              </Box>
              <Box whiteSpace="pre" fontFamily="rubik" color="brand.black" lineHeight="26px">
                {itemData.accessibility}
              </Box>
            </>
          )}
          {onEdit && (
            <Box ml="auto" mt="auto">
              <Button bg="rgba(106, 111, 247, 0.20)" borderRadius="base" w="40px" mr="8px" p={0} onClick={onEdit}>
                <Edit />
              </Button>
              <Button bg="rgba(224, 41, 63, 0.20)" borderRadius="base" w="40px" p={0} onClick={onRemove}>
                <Delete />
              </Button>
            </Box>
          )}
        </Flex>
      </Collapse>
    </Box>
  );
};

export default DisneyDayActivityCard;
