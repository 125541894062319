import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions as DaysActions, selectors as DaysSelectors } from "@containers/DisneyDays/store";
import { GetActivityDistanceParams } from "@containers/DisneyDays/interfaces";
import { setExecuteWithDayChangedWarning } from "@containers/DisneyDays/store/actions";

const useGetParkItemsInfo = (parkId: number | null) => {
  const dispatch = useDispatch();

  const parkAttractions = useSelector(DaysSelectors.getAttractions());
  const parkCharacters = useSelector(DaysSelectors.getCharacters());
  const parkEntertainments = useSelector(DaysSelectors.getEntertainments());
  const parkRestaurants = useSelector(DaysSelectors.getRestaurants());
  const parkRestrooms = useSelector(DaysSelectors.getRestrooms());
  const parkAreas = useSelector(DaysSelectors.getAreas());
  const currentDistance = useSelector(DaysSelectors.selectActivityDistance());

  useEffect(() => {
    if (!parkAttractions && parkId) {
      dispatch(DaysActions.getParksAttractions.request(parkId));
    }
  }, [dispatch, parkAttractions, parkId]);

  useEffect(() => {
    if (!parkCharacters && parkId) {
      dispatch(DaysActions.getParksCharacters.request(parkId));
    }
  }, [dispatch, parkCharacters, parkId]);

  useEffect(() => {
    if (!parkEntertainments && parkId) {
      dispatch(DaysActions.getParksEntertainments.request(parkId));
    }
  }, [dispatch, parkEntertainments, parkId]);

  useEffect(() => {
    if (!parkRestaurants && parkId) {
      dispatch(DaysActions.getParksRestaurants.request(parkId));
    }
  }, [dispatch, parkRestaurants, parkId]);

  useEffect(() => {
    if (!parkRestrooms && parkId) {
      dispatch(DaysActions.getParksRestrooms.request(parkId));
    }
  }, [dispatch, parkRestrooms, parkId]);

  useEffect(() => {
    if (!parkAreas && parkId) {
      dispatch(DaysActions.getParksAreas.request(parkId));
    }
  }, [dispatch, parkAreas, parkId]);

  const findActivityDistance = useCallback(
    (params: GetActivityDistanceParams) => {
      dispatch(DaysActions.getActivityDistance.request(params));
    },
    [dispatch],
  );

  const cleanUp = useCallback(() => {
    dispatch(DaysActions.getParksAttractions.cancel());
    dispatch(DaysActions.getParksCharacters.cancel());
    dispatch(DaysActions.getParksEntertainments.cancel());
    dispatch(DaysActions.getParksRestaurants.cancel());
    dispatch(DaysActions.getParksRestrooms.cancel());
    dispatch(DaysActions.getParksAreas.cancel());
    dispatch(DaysActions.getActivityDistance.cancel());
  }, [dispatch]);

  useEffect(
    () => () => {
      cleanUp();
      dispatch(DaysActions.getDay.cancel());
      dispatch(setExecuteWithDayChangedWarning(null));
    },
    [cleanUp, dispatch],
  );

  return {
    parkAttractions,
    parkCharacters,
    parkEntertainments,
    parkRestaurants,
    parkRestrooms,
    parkAreas,
    currentDistance,
    findActivityDistance,
    cleanUp,
  };
};

export default useGetParkItemsInfo;
