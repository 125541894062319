import config from "../../config";

export default {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
    REGISTER: `${config.baseApiUrl}auth/register`,
    FORGOT_PASSWORD: `${config.baseApiUrl}auth/forgotPassword`,
    SET_PASSWORD: `${config.baseApiUrl}auth/setPassword`,
    REGISTRATION: `${config.baseApiUrl}auth/registration`,
    ACTIVATE: `${config.baseApiUrl}auth/activate`,
  },
  USER: {
    GET_USER_INFO: `${config.baseApiUrl}user`,
  },
  DAY: {
    CREATE_DAY: `${config.baseApiUrl}day`,
    GET_DAY_LIST: `${config.baseApiUrl}day/list`,
    GET_DAY: (id: string) => `${config.baseApiUrl}day/${id}`,
    DELETE_DAY: (id: string) => `${config.baseApiUrl}day/${id}`,
    UPDATE_DAY: (id: string) => `${config.baseApiUrl}day/${id}`,
    COPY_DAY: (id: string) => `${config.baseApiUrl}day/copy/${id}`,
  },
  PARK: {
    GET_PARKS_LIST: `${config.baseApiUrl}park/list`,
    GET_PARK_ATTRACTIONS: (id: string) => `${config.baseApiUrl}park/${id}/attractions`,
    GET_PARK_CHARACTERS: (id: string) => `${config.baseApiUrl}park/${id}/characters`,
    GET_PARK_ENTERTAINMENTS: (id: string) => `${config.baseApiUrl}park/${id}/entertainments`,
    GET_PARK_RESTAURANTS: (id: string) => `${config.baseApiUrl}park/${id}/restaurants`,
    GET_PARK_RESTROOMS: (id: string) => `${config.baseApiUrl}park/${id}/restrooms`,
    GET_PARK_AREAS: (id: string) => `${config.baseApiUrl}park/${id}/areas`,
    GET_ACTIVITY_DISTANCE: (id: number) => `${config.baseApiUrl}park/${id}/distance`,
  },
};
