import { Box, Flex, Grid, HStack, Image, Text } from "@chakra-ui/react";
import React, { FC, Fragment } from "react";
import { ActivityType, DisneyDay } from "@shared/models";
import { ReactComponent as PlaceholderCastle } from "@assets/files/images/general/create-day-placeholder.svg";
import { ReactComponent as Location } from "@assets/files/icons/general/location.svg";
import {
  DisneyDayEditWaitTimeInfo,
  DisneyDayStatisticsCard,
  DisneyDayActivityCard,
  DisneyDayEditEnteredParkCard,
} from "@containers/DisneyDays/components";
import { getIntervalSeconds } from "@shared/utils";
import { getAvgWaitingTimeSeconds } from "@containers/DisneyDays/utils";

export interface DisneyDayEditProps {
  onBackClick: () => void;
  disneyDay: DisneyDay;
}

const DisneyDayView: FC<DisneyDayEditProps> = ({ disneyDay }) => {
  let attractionsCount = 0;
  let entertainmentsCount = 0;
  let charactersCount = 0;
  let restaurantsCount = 0;
  let generalDurationSeconds = 0;
  disneyDay.activities.forEach((activity) => {
    generalDurationSeconds +=
      getIntervalSeconds(activity.duration) +
      getAvgWaitingTimeSeconds(activity) +
      getIntervalSeconds(activity.walk_time);
    switch (activity.type) {
      case ActivityType.attraction:
        attractionsCount += 1;
        break;
      case ActivityType.entertainment:
        entertainmentsCount += 1;
        break;
      case ActivityType.character:
        charactersCount += 1;
        break;
      case ActivityType.restaurant:
        restaurantsCount += 1;
        break;
    }
  });

  return (
    <Flex gap={4} alignItems="stretch" h="calc(100% - 72px)">
      <Box
        w="424px"
        bg="brand.white"
        borderRadius="base"
        boxShadow="0px 4px 6px 0px rgba(42, 31, 106, 0.12)"
        p="24px 16px 16px"
        mb={4}
        flexShrink={0}
        overflowY="auto"
        overflowX="hidden"
        fontFamily="rubik"
      >
        <Box h="240px" w="392px" borderRadius="base" overflow="hidden">
          {disneyDay.image ? <Image src={disneyDay.image} alt="avatar" objectFit="cover" /> : <PlaceholderCastle />}
        </Box>
        <HStack color="brand.subtitle" spacing={1} mt={3} mb={4}>
          <Location />
          <Text fontWeight={700} color="brand.black">
            {disneyDay.park?.name}
          </Text>
        </HStack>
        {disneyDay.description && (
          <Box lineHeight="25px">
            {disneyDay.description.split("\n").map((line, i) => (
              <Fragment key={i}>
                {i !== 0 && <br />}
                {line}
              </Fragment>
            ))}
          </Box>
        )}
        <Grid templateColumns="186px 186px" gap="13px 18px" mt={4}>
          <DisneyDayStatisticsCard type="hours" count={Math.round(generalDurationSeconds / 3600)} />
          <DisneyDayStatisticsCard type="steps" count={disneyDay.steps || 0} />
          <DisneyDayStatisticsCard type="attractions" count={attractionsCount} />
          <DisneyDayStatisticsCard type="entertainments" count={entertainmentsCount} />
          <DisneyDayStatisticsCard type="characters" count={charactersCount} />
          <DisneyDayStatisticsCard type="restaurants" count={restaurantsCount} />
        </Grid>
        <HStack justifyContent="space-between" px={4} borderRadius="base" bgColor="brand.bg" h="48px" mt={4}>
          <Text fontSize="14px" color="brand.subtitle">
            Minimum height
          </Text>
          <Text>{disneyDay.height ? `${disneyDay.height} inches` : "Any Height"}</Text>
        </HStack>
      </Box>
      <Box
        bg="brand.white"
        borderRadius="base"
        boxShadow="0px 4px 6px 0px rgba(42, 31, 106, 0.12)"
        p={4}
        mb={4}
        w="full"
      >
        <Box textStyle="editMainCardTitle" mb={3}>
          ITINERARY
        </Box>
        <Box overflowY="auto" h="calc(100% - 38px)">
          <DisneyDayEditWaitTimeInfo />
          <HStack alignItems="stretch" spacing={5}>
            <Box
              borderRight={disneyDay.activities?.length ? "2px dashed rgba(152, 135, 255, 0.40)" : undefined}
              ml={3}
              flexShrink={0}
            />
            <Flex flexDirection="column" gap={4} w="full">
              <DisneyDayEditEnteredParkCard title={disneyDay.park?.name || ""} />
              {disneyDay.activities?.map((activity, i) => (
                <DisneyDayActivityCard
                  key={activity.id}
                  activity={activity}
                  isLast={i + 1 === disneyDay.activities.length}
                />
              ))}
            </Flex>
          </HStack>
        </Box>
      </Box>
    </Flex>
  );
};

export default DisneyDayView;
