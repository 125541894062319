export const Checkbox = {
  variants: {
    selectOption: {
      container: {
        _disabled: {
          cursor: "pointer",
        },
      },
      control: {
        height: "20px",
        width: "20px",
        _disabled: {
          borderColor: "gray.50",
          backgroundColor: "brand.white",
        },
        _checked: {
          _disabled: {
            borderColor: "blue.500",
            backgroundColor: "blue.500",
            color: "white",
          },
        },
      },
      label: {
        _disabled: {
          opacity: 1,
          color: "brand.black",
          cursor: "pointer",
        },
        _checked: {
          _disabled: {
            color: "brand.black",
            cursor: "pointer",
          },
        },
      },
    },
  },
};
