import { createAction, createAsyncAction } from "typesafe-actions";
import { DaysFilter, ResponseError } from "@shared/interfaces";
import { DaysActionTypes } from "@containers/DisneyDays/store/constants";
import {
  ActivityDistance,
  Area,
  Attraction,
  Character,
  DisneyDay,
  Entertainment,
  Restaurant,
  RestRoom,
} from "@shared/models";
import { CreateDisneyDay, GetActivityDistanceParams, UpdateDisneyDay } from "@containers/DisneyDays/interfaces";
import { Park } from "@shared/models/Park";

export const getDaysList = createAsyncAction(
  DaysActionTypes.GET_DAYS_LIST,
  DaysActionTypes.GET_DAYS_LIST_SUCCESS,
  DaysActionTypes.GET_DAYS_LIST_FAILURE,
)<DaysFilter, DisneyDay[], ResponseError>();

export const getDay = createAsyncAction(
  DaysActionTypes.GET_DAY,
  DaysActionTypes.GET_DAY_SUCCESS,
  DaysActionTypes.GET_DAY_FAILURE,
  DaysActionTypes.GET_DAY_CLEAR,
)<number, DisneyDay, ResponseError, void>();

export const createDay = createAsyncAction(
  DaysActionTypes.CREATE_DAY,
  DaysActionTypes.CREATE_DAY_SUCCESS,
  DaysActionTypes.CREATE_DAY_FAILURE,
)<CreateDisneyDay, DisneyDay, ResponseError>();

export const updateDay = createAsyncAction(
  DaysActionTypes.UPDATE_DAY,
  DaysActionTypes.UPDATE_DAY_SUCCESS,
  DaysActionTypes.UPDATE_DAY_FAILURE,
)<UpdateDisneyDay, DisneyDay, ResponseError>();

export const deleteDay = createAsyncAction(
  DaysActionTypes.DELETE_DAY,
  DaysActionTypes.DELETE_DAY_SUCCESS,
  DaysActionTypes.DELETE_DAY_FAILURE,
)<number, void, ResponseError>();

export const copyDay = createAsyncAction(
  DaysActionTypes.COPY_DAY,
  DaysActionTypes.COPY_DAY_SUCCESS,
  DaysActionTypes.COPY_DAY_FAILURE,
)<number, DisneyDay, ResponseError>();

export const getParksList = createAsyncAction(
  DaysActionTypes.GET_PARKS_LIST,
  DaysActionTypes.GET_PARKS_LIST_SUCCESS,
  DaysActionTypes.GET_PARKS_LIST_FAILURE,
)<undefined, Park[], ResponseError>();

export const getParksAttractions = createAsyncAction(
  DaysActionTypes.GET_PARK_ATTRACTIONS,
  DaysActionTypes.GET_PARK_ATTRACTIONS_SUCCESS,
  DaysActionTypes.GET_PARK_ATTRACTIONS_FAILURE,
  DaysActionTypes.GET_PARK_ATTRACTIONS_CLEAR,
)<number, Attraction[], ResponseError, void>();

export const getParksCharacters = createAsyncAction(
  DaysActionTypes.GET_PARK_CHARACTERS,
  DaysActionTypes.GET_PARK_CHARACTERS_SUCCESS,
  DaysActionTypes.GET_PARK_CHARACTERS_FAILURE,
  DaysActionTypes.GET_PARK_CHARACTERS_CLEAR,
)<number, Character[], ResponseError, void>();

export const getParksEntertainments = createAsyncAction(
  DaysActionTypes.GET_PARK_ENTERTAINMENTS,
  DaysActionTypes.GET_PARK_ENTERTAINMENTS_SUCCESS,
  DaysActionTypes.GET_PARK_ENTERTAINMENTS_FAILURE,
  DaysActionTypes.GET_PARK_ENTERTAINMENTS_CLEAR,
)<number, Entertainment[], ResponseError, void>();

export const getParksRestaurants = createAsyncAction(
  DaysActionTypes.GET_PARK_RESTAURANTS,
  DaysActionTypes.GET_PARK_RESTAURANTS_SUCCESS,
  DaysActionTypes.GET_PARK_RESTAURANTS_FAILURE,
  DaysActionTypes.GET_PARK_RESTAURANTS_CLEAR,
)<number, Restaurant[], ResponseError, void>();

export const getParksRestrooms = createAsyncAction(
  DaysActionTypes.GET_PARK_RESTROOMS,
  DaysActionTypes.GET_PARK_RESTROOMS_SUCCESS,
  DaysActionTypes.GET_PARK_RESTROOMS_FAILURE,
  DaysActionTypes.GET_PARK_RESTROOMS_CLEAR,
)<number, RestRoom[], ResponseError, void>();

export const getParksAreas = createAsyncAction(
  DaysActionTypes.GET_PARK_AREAS,
  DaysActionTypes.GET_PARK_AREAS_SUCCESS,
  DaysActionTypes.GET_PARK_AREAS_FAILURE,
  DaysActionTypes.GET_PARK_AREAS_CLEAR,
)<number, Area[], ResponseError, void>();

export const getActivityDistance = createAsyncAction(
  DaysActionTypes.GET_ACTIVITY_DISTANCE,
  DaysActionTypes.GET_ACTIVITY_DISTANCE_SUCCESS,
  DaysActionTypes.GET_ACTIVITY_DISTANCE_FAILURE,
  DaysActionTypes.GET_ACTIVITY_DISTANCE_CLEAR,
)<GetActivityDistanceParams, ActivityDistance, ResponseError, void>();

export const setFilter = createAction(DaysActionTypes.SET_FILTER)<DaysFilter | null>();

export const clearDaysList = createAction(DaysActionTypes.CLEAR_DAYS_LIST)();

export const setExecuteWithDayChangedWarning = createAction(DaysActionTypes.SET_EXECUTE_WITH_DAY_CHANGED_WARN_FUNC)<
  ((func: () => void) => void) | null
>();
