import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

export interface ActivityInfoItemProps {
  title: string;
  value: string;
  icon?: React.ReactNode;
}

const ActivityInfoItem: FC<ActivityInfoItemProps> = (props) => {
  const { title, value, icon } = props;
  return (
    <Box display="flex" alignItems="center" mr="16px">
      {icon && icon}
      <Box textStyle="cardName" ml="4px" mr="8px">
        {title}
      </Box>
      <Box>{value}</Box>
    </Box>
  );
};
export default ActivityInfoItem;
