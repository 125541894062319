import { FONT_WEIGHT } from "@assets/theme/foundations/textStyle";

export const Button = {
  baseStyle: {
    height: "40px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  variants: {
    secondary: {
      backgroundColor: "brand.secondaryButtonBgActive",
      color: "brand.white",
      borderRadius: "base",
      _disabled: {
        backgroundColor: "brand.secondaryButtonBg",
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "brand.secondaryButtonBgActive",
        _disabled: {
          backgroundColor: "brand.secondaryButtonBg",
          opacity: 0.6,
        },
      },
    },
    addActivity: {
      backgroundColor: "brand.primary",
      color: "brand.white",
      borderRadius: "base",
      _disabled: {
        backgroundColor: "brand.secondaryButtonBg",
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "brand.primary",
        _disabled: {
          backgroundColor: "brand.secondaryButtonBg",
          opacity: 0.6,
        },
      },
    },
    cancel: {
      backgroundColor: "transparent",
      color: "brand.cancel",
      fontSize: "16px",
      fontWeight: FONT_WEIGHT.BOLD,
    },
    primary: {
      backgroundColor: "brand.primary",
      color: "brand.white",
      _disabled: {
        backgroundColor: "brand.primary",
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "brand.primary",
        _disabled: {
          backgroundColor: "brand.primary",
          opacity: 0.6,
        },
      },
    },
    ghost: {
      backgroundColor: "transparent",
      color: "brand.orange",
      _disabled: {
        opacity: 0.6,
      },
      _hover: {
        backgroundColor: "transparent",
      },
      _active: {
        backgroundColor: "transparent",
      },
    },
    imageActionButton: {
      borderRadius: "16px",
      minWidth: "24px",
      maxWidth: "24px",
      minHeight: "24px",
      maxHeight: "24px",
      p: "0",
    },
  },
};
