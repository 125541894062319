import React, { FC, useCallback, useId } from "react";
import { Select } from "chakra-react-select";
import { SelectProps } from "@shared/interfaces";
import { FormLabel } from "@chakra-ui/react";

const InputSelect: FC<SelectProps> = (props) => {
  const id = useId();
  const onMenuOpen = useCallback(() => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(`${id}__menu`)[0];
      if (selectedEl) {
        const rect = selectedEl.getBoundingClientRect();
        if (rect.bottom > document.documentElement.clientHeight - 32) {
          selectedEl.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
        }
      }
    }, 0);
  }, [id]);

  return (
    <>
      {props?.label && (
        <FormLabel color="brand.label" fontSize="14px">
          {props.label}
        </FormLabel>
      )}
      <Select
        {...props}
        isSearchable={true}
        classNamePrefix={id}
        minMenuHeight={300}
        onMenuOpen={onMenuOpen}
        chakraStyles={{
          container: (provided) => ({
            ...provided,
            cursor: "pointer",
          }),
          control: (provided) => ({
            ...provided,
            height: "40px",
            borderRadius: "base",
            borderColor: "gray.100 !important",
            boxShadow: "none !important",
            fontSize: "16px",
            background: "white",
            opacity: "1 !important",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            background: "none",
          }),
          downChevron: (provided) => ({
            ...provided,
            color: "#8D95B2",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#8D95B2" : state.isFocused ? "base.white" : "transparent",
            fontSize: "16px",
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: state.isDisabled ? "brand.label" : "brand.black",
          }),
        }}
      />
    </>
  );
};

export default InputSelect;
