export const Textarea = {
  variants: {
    textAreaCreate: {
      height: "152px",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "26px",
      borderRadius: "base",
      border: "1px solid #DFDFED",
    },

    // defaultProps: {
    //   focusBorderColor: "red.200",
    // },
  },
};
