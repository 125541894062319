import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";
import { DaysFilter } from "@shared/interfaces";
import { DayStateType } from "@containers/DisneyDays/interfaces/DisneyDaysState.interface";

import * as actions from "./actions";

type Action = ActionType<typeof actions>;

const defaultFilter: DaysFilter = {};

export const initialState: DayStateType = {
  days: [],
  daysTotal: 0,
  filter: { ...defaultFilter },
  day: null,
  parks: null,
  attractions: null,
  characters: null,
  entertainments: null,
  restaurants: null,
  restrooms: null,
  areas: null,
  distance: null,
  executeWithDayChangedWarning: null,
};

const reducer = createReducer<DayStateType, Action>(initialState)
  .handleAction(actions.getDaysList.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.days = action.payload;
      //TODO: change after full BE pagination implementation
      // nextState.daysTotal = action.payload.length;
    }),
  )
  .handleAction(actions.setFilter, (state, action) =>
    produce(state, (nextState) => {
      nextState.filter = action.payload || { ...defaultFilter };
    }),
  )
  .handleAction(actions.clearDaysList, (state) =>
    produce(state, (nextState) => {
      nextState.days = [];
    }),
  )
  .handleAction(actions.getDay.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.day = action.payload;
    }),
  )
  .handleAction(actions.getDay.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.day = null;
    }),
  )
  .handleAction(actions.createDay.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.day = action.payload;
    }),
  )
  .handleAction(actions.updateDay.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.day = action.payload;
    }),
  )
  .handleAction(actions.copyDay.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.day = action.payload;
      nextState.days = [action.payload, ...nextState.days];
    }),
  )
  .handleAction(actions.deleteDay.success, (state) =>
    produce(state, (nextState) => {
      nextState.day = null;
    }),
  )

  .handleAction(actions.getParksList.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.parks = action.payload;
    }),
  )
  .handleAction(actions.getParksAttractions.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.attractions = action.payload;
    }),
  )
  .handleAction(actions.getParksCharacters.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.characters = action.payload;
    }),
  )
  .handleAction(actions.getParksEntertainments.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.entertainments = action.payload;
    }),
  )
  .handleAction(actions.getParksRestaurants.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.restaurants = action.payload;
    }),
  )
  .handleAction(actions.getParksRestrooms.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.restrooms = action.payload;
    }),
  )
  .handleAction(actions.getParksAreas.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.areas = action.payload;
    }),
  )
  .handleAction(actions.getActivityDistance.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.distance = action.payload;
    }),
  )

  .handleAction(actions.getParksAttractions.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.attractions = null;
    }),
  )
  .handleAction(actions.getParksCharacters.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.characters = null;
    }),
  )
  .handleAction(actions.getParksEntertainments.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.entertainments = null;
    }),
  )
  .handleAction(actions.getParksRestaurants.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.restaurants = null;
    }),
  )
  .handleAction(actions.getParksRestrooms.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.restrooms = null;
    }),
  )
  .handleAction(actions.getParksAreas.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.areas = null;
    }),
  )
  .handleAction(actions.getActivityDistance.cancel, (state) =>
    produce(state, (nextState) => {
      nextState.distance = null;
    }),
  )
  .handleAction(actions.setExecuteWithDayChangedWarning, (state, action) =>
    produce(state, (nextState) => {
      nextState.executeWithDayChangedWarning = action.payload;
    }),
  );
export { reducer as DaysReducer };
